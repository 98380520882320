import flagFr from "../style/assets/splashscreen/flags/fr.png";
import flagEn from "../style/assets/splashscreen/flags/en.png";
import flagDe from "../style/assets/splashscreen/flags/de.png";

export const trads = [
	{
		id: "fr",
		flag: `url(${flagFr})`,
		lang: "Français",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "Sur les traces de l'Homme",
		start: "Démarrer",
		/* Menu */
		timeline: "Frise",
		map: "Carte",
		glossary: "Lexique",
		/* Map */
		repereMap: "Repérez-vous sur la carte",
		enjoyExp:
			"Pour profiter à fond de l'expérience Furfooz, nous vous conseillons de partager votre localisation",
		letsgo: "C'est parti !",
		notNow: "Je ne suis pas sur place",
		ok: "Ok",
		dotInactiv1: "Ce point n'est pas encore actif !",
		dotInactiv2: "Vous devez d'abord déverouiller les points précédents.",
		restartExp: "Recommencer l'expérience ?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Un jeu immersif pour découvrir les trésors de Furfooz sur les traces d’Edmond, le célèbre archéologue belge.",
		manyMarquage:
			"Rendez-vous sur les différents marqueurs du parcours et trouvez sur place le code secret qui révélera des contenus exclusif et de nombreuses surprises !",
		playingInPlace: 'Jouer en mode "à distance"',
		cutGeoloc:
			"Couper la géolocalisation et accéder aux contenus sans répondre aux énigmes",
		resetExp: "Réinitialiser l'expérience",
		credits: "Crédits",
		timescopeProject: "Un projet Timescope",
		creditsContent: "%bContenus%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotographie%b Photographe",
		creditsTexts: "%bTextes %bOona Farell",
		creditsDesign: "%bDesign de l'application mobile%b Felix Marquette",
		/* SecretCode */
		secretCode: "Code secret",
		validate: "Valider",
		validate2: "Ré-essayer",
		badanswer: "Ce n'est pas ça...",
		bravo: "Bravo !",
		continue: "Continuer",
		slideToDiscover: "Cliquer pour continuer",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Légende de l'image",
		backMap: "Retour à la carte",
		knowMoreWith: "En savoir plus",
		backTime: "Remonter le temps",
		nowTime: "Retour au présent",
		temporalFilter: "Filtre temporel",

		t300ad: "300 ap. J.-C.",
		t1200ad: "1200 ap. J.-C.",
		t10000bc: "10 000 av. J.-C.",

		/* Timeline */
		timelineTitle: "Frise Chronologique",
		scrollArrowsExpl:
			"Pssst... Si vous êtes fatigués de scroller, utilisez les flèches pour vous déplacer plus rapidement d'une période à l'autre !",
		explanationTimeline:
			"L'époque moderne ne représente qu'une petite fraction de l'histoire de l'humanité. Serez-vous capable de remontrer jusqu'aux traces des premiers humains à Furfooz dans cette frise où chaque pixel correspond à 1 an ?",
		timelineBottom: "Vous êtes arrivés au bout ! Bravo :)",
		timelineBackFutur: "Retour vers le futur",
		showMoreButton: "Voir plus",
		/* Glossary */
		explanationGlossaryLine1:
			"Voici les termes techniques que vous avez pu rencontrer jusqu'à présent.",
		explanationGlossaryLine2:
			"Découvrez d'autres points d'intérêts pour remplir votre lexique !",
		unlockTo: "Débloqué aux ",
		glossaryUnlock: "Nouveau terme ajouté au lexique !",
		goGlossary: "Voir le lexique",
		/* Cookies */
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour sauvegardez vos progrès dans le jeu. En poursuivant l’expérience vous acceptez notre usage de cookies.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire notre politique de confidentialité.",
		/* PrivatePolicy */
		privatePolicyTitle: "Politique de confidentialité",
		/* EndExp */
		welldone: "Beau travail !",
		allfound: "Vous avez trouvé tous les trésors de Furfooz.",
		allfound2: "Edouard Dupont serait fier de vous.",
		shareExp: "Partagez votre expérience",
		shareExpWithFriends: "Partagez votre expérience avec vos amis !",
		link: "Lien",
		x: "X",
		whatsapp: "Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Vous avez résolu la première énigme !",
		thermalBathsRiddleTitle: "Pierre calcaire",
		thermalBathsRiddleDesc:
			"Fantastique ! En effet, les thermes sont bâtis à l’aide de pierres calcaires. Pour lier l’ouvrage, les Romains ont utilisé du mortier de teinte grisâtre compact et du fin gravier de rivière. Et puis, le plateau de Hauterecenne est fait de pierres calcaires, il n’y a plus qu’à se servir.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Félicitations pour cette deuxième énigme !",
		hauterecenneRiddleTitle: "Pièce frappée",
		hauterecenneRiddleDesc:
			"Bien joué ! Sur le plateau de Hauterecenne, les archéologues ont découvert des pièces de monnaie en grande quantité… et traversant bien des siècles !",

		/* Nutons */
		nutonsRiddleDisc: "Encore une énigme réussie !",
		nutonsRiddleTitle: "Bois de renne gravé",
		nutonsRiddleDesc:
			"Bravo ! Au Trou des Nutons, on a découvert un bois de renne sur lequel est gravé une tête de bison, et lorsqu’on le retourne, on y voit un mammouth !",

		/* Frontal */
		frontalRiddleDisc: "Vous avez résolu la quatrième énigme !",
		frontalRiddleTitle: "Os frontal d’un adolescent",
		frontalRiddleDesc:
			"Félicitation ! Edouard Dupont a découvert en 1864 un os frontal appartenant à un adolescent ainsi que deux crânes humains intacts conservés dans un ossuaire.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Encore une énigme, continuez ainsi !",
		goldenPaddleRiddleTitle: "Bout d’une roche avec des sédiments",
		goldenPaddleRiddleDesc:
			"Bravo ! Ce bout de roche est une source d’information précieuse pour les paléontologues. À l’intérieur, on y voit des sédiments, un dépôt qui se forme par la précipitation des matières en suspension ou dissoutes dans un liquide. Grâce à eux, nous pouvons dater le passage de l’Homme dans la grotte.",

		/* Reuviau */
		reuviauRiddleDisc: "C'était la dernière énigme !",
		reuviauRiddleTitle: "Silex taillé magdalénien",
		reuviauRiddleDesc:
			"Fantastique ! Vous êtes arrivés à la fin de votre parcours. Les silex taillés sont les outils emblématiques de la Préhistoire. Les cavités de Furfooz ont livré de belles séries de silex taillés magdaléniens et du Néolithique moyen.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		blank: "",

		/* Epoque contemporaine */
		epoqueContemporaine: "Époque contemporaine",
		epoqueContemporaineFull: "Époque contemporaine | 1789 à nos jours",
		y2024: "Le site de Furfooz est à nouveau fouillé ! Cette campagne se concentre sur le plateau de Hauterecenne.",
		y1985y1986: "Campagne de fouilles archéologiques dans plusieurs cavernes préhistoriques, sous la direction du Professeur M. Otte.",
		y1974y1976: "Campagne de fouilles du site romain et médiéval sous la direction de M.R.Brulet, restauration des murs de fortifications et dégagement de la base du donjon.",
		y1962: "Découverte à Furfooz du trajet de la Lesse souterraine.",
		y1956: "Fin des travaux de reconstruction des thermes romains à Furfooz, sous la direction de M.J. Breuer",
		y1948: "Furfooz est classée réserve naturelle.",
		y1900y1902: "De nouvelles fouilles sont entreprises à Furfooz par Rahir. Il met au jour l’Abri de l’Ossuaire, le Trou du Crâne ou encore le Trou de la Mâchoire.",
		y1872: "À la suite de ses recherches commencées dans les grottes mosanes en 1864, Edouard Dupont publie Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse. L'ouvrage reprend ses études des trous, condense tout son parcours et livre ses conclusions au sujet de la Préhistoire en Belgique.",
		y1868: "Edouard Dupont prend la direction du Musée royal d’Histoire naturelle de Belgique, haute fonction qu’il assurera de manière éclairée et autoritaire durant 41 années en agrandissant les espaces et les collections.",
		y1865: "Edouard Dupont fouille le Trou Reuviau et le Trou Rosette.",
		y1864: "Edouard Dupont est le premier archéologue mandaté par le Gouvernement belge pour effectuer des fouilles préhistoriques dans les grottes du bassin mosan. Dupont commence dans les grottes de la vallée de la Lesse, en particulier par le Trou des Nutons à Furfooz. Ces travaux sont considérés comme les premières fouilles de grottes officielles au monde.",
		y1841: "Naissance d'Édouard Dupont.",
		y1829y1833: "Le docteur Ph.C.Schmerling fouille pour la première fois les grottes préhistoriques en Wallonie. C’est l’un des premiers a apporté la preuve de la contemporanéité de l’homme avec des espèces disparues comme le mammouth ou le rhinocéros laineux.",
		y1830Title: "Indépendance de la Belgique",
		y1830: "L'occupation de Bruxelles par l'armée hollandaise, entre le 23 et le 27 septembre 1830, provoque la création d'un comité révolutionnaire. Ce comité se mue en gouvernement provisoire, après le repli des Hollandais. Le 4 octobre 1830, le gouvernement provisoire proclame l'indépendance des neuf provinces belges. Il charge une commission d'élaborer une nouvelle constitution.",
		y1815Title: "Promulgation de la constitution des Pays-Bas unis",
		y1815: "Afin d’intégrer parfaitement les provinces « belges » aux provinces « hollandaises », le roi Guillaume d’Orange fait revoir la Grondwet de mars 1814 et ses 146 articles par une commission hollando-belge. Guillaume Ier promulgue le texte de la nouvelle Constitution, le 24 août 1815.",
		y1793march26Title: "Seconde restauration autrichienne",
		y1793march26: "Alors que les Français se replient dans le nord après une série de victoires de l’armée autrichienne, les Autrichiens rétablissent le prince-évêque sur son siège liégeois et entrent à Bruxelles le 26 mars 1793. Accueillis favorablement par la population, ils rétablissent les Pays-Bas autrichiens dans leur statut antérieur et l’empereur désigne un nouveau gouverneur en la personne de l’archiduc Charles.",
		y1793march01Title: "Annexion à la France du pays wallon",
		y1793march01: "La Convention nationale décide, depuis Paris, l’annexion à la France de l’ensemble des anciens territoires de la principauté de Liège, de la principauté abbatiale de Stavelot-Malmedy et des provinces des Pays-Bas autrichiens. Commissaire de la république désigné à Liège et à Namur, Danton demande cette annexion le 31 janvier lors de son fameux discours sur la théorie des frontières naturelles.",
		y1792y1793: "Le Trou des Nutons à Furfooz sert aux Français de fabrique de salpêtre, une substance servant à la fabrication de la poudre à canon.",
		y1789y1791Title: "Révolution liégeoise",
		y1789y1791: "Ce mouvement révolutionnaire d'inspiration démocratique fait écho, dans la principauté de Liège, au début de la Révolution française. Quand les français prennent la Bastille le 14 juillet, les insurgés prennent l'hôtel de ville le 18 août 1789. Cela provoque l'exil du prince-évêque le 26 août. Celui-ci, momentanément rétabli par l'Autriche en février 1791, procéda à une sévère répression.",

		
		/* Période Moderne */
		periodeModerne: "Période moderne",
		periodeModerneFull: "Période moderne | 1492 à 1789",
		periodeModerneDesc: "Traditionnellement, les historiens font débuter la période Moderne à la découverte des Amériques et font coïncider sa fin avec celle de la Révolution française. Après les différentes révolutions, on appelle également cette période l’Ancien Régime.",
		y1789y1799Title: "Révolution française",
		y1789y1799: "Cette période de bouleversements politiques et sociaux en France et dans ses colonies va inspirer l’ensemble de l’Europe, y compris la Belgique. Traditionnellement, on la fait commencer à l'ouverture des États généraux le 5 mai 1789 et finir au coup d'État de Napoléon Bonaparte le 9 novembre 1799 (18 brumaire de l'an VIII). La Révolution met fin à l'Ancien Régime, notamment à la monarchie absolue remplacée par la monarchie constitutionnelle (1789-1792), puis par la Première République.",
		y1776: "Déclaration d'indépendance des Etats-Unis d'Amérique (13 colonies).",
		y1748Title: "Traité d’Aix-la-Chapelle",
		y1748: "Restitution du pays wallon (Tournai, Mons, Charleroi, Namur) par Louis XV aux Pays-Bas de l'impératrice Marie-Thérèse. Entamée en 1740, la Guerre de Succession d’Autriche touche le pays wallon lorsque les troupes françaises s’emparent tour à tour de Tournai, Mons, Charleroi et Namur. Parti à la conquête des Provinces-Unies, Louis XV finit par accepter un traité de Paix, en 1748, signé à Aix-la-Chapelle. Le pays wallon est restitué à l’impératrice Marie-Thérèse, affaiblie par rapport à la Prusse : les Pays-Bas autrichiens sont reconstitués pour quelques années.",
		y1568y1648Title: "Guerre de Quatre-Vingt-Ans ou révolte des Pays-Bas.",
		y1568y1648: "Soulèvement armé mené contre la monarchie espagnole par une partie des Dix-Sept Provinces des Pays-Bas espagnols. Ayant pris forme autour de la révolte des Gueux, revendiquant la liberté religieuse pour le protestantisme, la révolte prend aussi la forme d'une guerre civile entre les sept provinces du Nord (actuels Pays-Bas), où la Réforme est bien ancrée, et les Pays-Bas méridionaux (actuels Belgique, Luxembourg et Nord-Pas-de-Calais), catholiques et loyalistes.",
		y1566: "Deux cents usines fonctionnent en territoire wallon, la France entière n’en compte que le double. Le pays wallon s’impose comme l’une des régions sidérurgiques les plus denses d’Europe occidentale.",
		sXVIsXVIII: "les provinces wallonnes relèvent des Pays-Bas dirigés par les Habsbourg, d'Espagne, d’abord, jusqu'en 1714, puis d'Autriche, jusqu'en 1794.",
		y1566: "C’est la plus ancienne mention du mot « wallon » retrouvée par Albert Henry dans les Mémoires du chroniqueur Jean de Haynin. le terme wallon devient alors d’usage général dans un ensemble qui met en présence un bloc de langue romane et un bloc de langue thioise dans un même cadre politique.",
		y1517: "Naissance du protestantisme et de la Réforme luthérienne.",

		/* Moyen Âge */
		moyenAge: "Moyen Âge",
		moyenAgeFull: "Moyen Âge | 476 au XVe siècle",
		moyenAgeDesc: "Période commençant au Ve siècle après J.-C. avec l'effondrement et le morcellement politique et culturel de l'Empire romain et s'achevant vers la fin du XVe siècle. avec l'essor de la Renaissance italienne, la naissance des nations modernes et la découverte des nouveaux continents.",
		y1492:"Découverte de l’Amérique. Début de la période moderne.",
		y1454:"Invention de l’imprimerie.",
		sXIII:"Le site de Hauterecenne perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		y1000y1100: "Développent des châteaux forts en pierre à vocation défensive.",
		sXIsXIIITitle: "Moyen Âge classique",
		sXIsXIII: "Aussi appelée Moyen Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		y476sXTitle: "Haut Moyen Âge",
		y476sX: "Période qui s’étend de la fin de l’Antiquité à la fin de la période carolingienne, soit à la fin du Xe siècle. C’est au début de cette période que la forteresse de Hauerecenne est occupée de façon permanente.",
		sV: "Diffusion du christiannisme en Wallonie.",
		sVsVIIITitle: "Période Mérovingienne",
		sVsVIII: "Les Mérovingiens forment une dynastie qui régna du Vᵉ jusqu'au milieu du VIIIᵉ siècle sur une très grande partie de la France et de la Belgique actuelles, ainsi que sur des parties de l'Allemagne, des Pays-Bas et de la Suisse.",

		/* Antiquité */
		antiquite: "Antiquité",
		antiquiteFull: "Antiquité | -3500 à +476",
		antiquiteDesc: "Période qui s’étend de l’invention de l’écriture au début du Moyen Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		y303Title: "Réorganisation administrative de l’empire par Dioclétien",
		y303: "Quand l’empereur Dioclétien (284-305) procède à la réorganisation générale de l’Empire, il distingue désormais, dans la province Belgica, la Belgique supérieure ou première (cap. Trèves), la Belgique inférieure ou seconde (cap. Reims). Quant à la Germanie inférieure, elle est désormais rebaptisée Germania Secunda : c’est là que se situe la civitas Tungrorum traversée en son milieu par la Meuse, entre Dinant et son embouchure. La réforme de Dioclétien semble effective dès 303.",
		sIIIsIV: "Occupation continue de la forteresse de Hauterecenne.",
		sIIIy476Title: "Bas-Empire",
		sIIIy476: "Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen Âge.",
		ya52Title: "César conquiert la Gaule",
		ya52: "C’est le début de la romanisation. Ainsi, les habitants de Wallonie deviennent gallo-romains, abandonnent leurs langues celtes au profit du latin, intègrent la culture et, progressivement, acquièrent la citoyenneté romaine.",
		ya450Title: "Les Celtes s’implantent en Wallonie",
		ya450: "Ils seront à la base d’une riche civilisation, notamment en matière d’industrie (métallurgie) et d’artisanat de qualité. Dès ce moment, les Germains utilisent le terme Walha pour désigner ces Celtes habitant aux frontières sud et ouest de leurs régions. Les Celtes s’illustrent par leur métallurgie (bas-fourneaux), pratiquant la forge et un artisanat de qualité inscrit dans de véritables circuits commerciaux.",
		ya750ya27Title: "Âge du Fer",
		ya750ya27: "Période où les sociétés se structurent considérablement. La sidérurgie se développe dans nos régions. L’époque de Hallstatt (ou Premier Âge du Fer) s'étend de 750 à 480 av. JC et se caractérise par un renforcement du pouvoir. Parallèlement, les richesses sont concentrées entre les mains de quelques individus et la société est par conséquent de plus en plus hiérarchisée, de nombreuses fortifications sont érigées. La période de La Tène (ou Second Âge du Fer), située entre 480 av. J-C et le règne d’Auguste, est quant à elle marquée par un morcellement du pouvoir, des tribus tissant alors entre elles un réseau d’alliances et de clientèle.",
		ya2300ya750Title: "Âge du Bronze",
		ya2300ya750: "Sédentarisées depuis le Néolithique, les hommes et les femmes de l’âge du Bronze perfectionnent leurs techniques agricoles et deviennent des virtuoses de la métallurgie du bronze. C’est aussi le moment de la naissance de l’écriture en Mésopotamie.",

		/* Neolithique */
		neolithique: "Néolithique",
		neolithiqueFull: "Néolithique | -6500 à -2100",
		neolithiqueDesc: "Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		ya4000Title: "Néolithique moyen",
		ya4000: "Développement du procédé d’inhumation, c’est-à-dire d’enterrer ses morts, dans toute la Belgique. Aussi, la Wallonie connaît son âge d’or préhistorique, marqué notamment par l’érection de fortifications et une exploitation « industrielle » du silex.",
		ya6000Title: "Néolithique supérieur et les premières traces néolithiques en Wallonie",
		ya6000: "Le courant de néolithisation de l’Europe du Nord-Ouest né il y a environ 10 000 ans au Proche-Orient. Il se propage vers l’ouest jusque dans les Balkans avant de se scinder en deux : une partie s’est développée dans le Sud de la France et en Espagne et l’autre dans les Balkans et le bassin du Danube moyen, où s’est constituée la civilisation dite rubanée, en référence aux décors en ruban des poteries. Le Danubien a ensuite gagné le nord de l’Europe jusqu’à nos régions. Les premiers agriculteurs établis « en pays wallon » proviennent donc de ces plaines danubiennes. Cette civilisation des premiers hommes néolithiques, des fermiers semi-sédentaires habitant de grandes maisons de bois, a gagné toute la Hesbaye liégeoise et hennuyère et s’est mêlée aux populations mésolithiques restées prédatrices dans le Condroz et en Campine.",

		/* Mésolithique */
		mesolithique: "Mésolithique",
		mesolithiqueFull: "Mésolithique | -9600 à -6000",
		mesolithiqueDesc: "Aussi appelée Epipaléolithique, cette période intermédiaire est marquée par ce brusque réchauffement climatique du début de notre actuelle période interglaciaire. Les hommes du Paléolithique s’y adaptent et adoptent un mode de vie semi-nomade. Les déplacements saisonniers des chasseurs-cueilleurs deviennent moins fréquents. Ils conservent néanmoins un mode de vie basé sur la chasse, la pêche et la cueillette, qui s’achève avec l’arrivée des agriculteurs-éleveurs du Néolithique, autour de - 6000 en France.",
		ya5500ya2000Title: "Transgression marine dunkerquienne",
		ya5500ya2000: "Transgression marine de la mer du Nord, c’est-à-dire l’avancée de trait de côte sur à la montée du niveau de la mer, qui envahit progressivement la plaine côtière notamment flamande. Les eaux occupent alors la plaine maritime flamande en y déposant des sables sur 25 à 30 mètres d'épaisseur. Pendant les périodes de repli, les rivières descendant des collines ont apporté des vases argileuses sur lesquelles la végétation s'est installée.",

		/* Paléolithique supérieur */
		paleolitiqueSup: "Paléolithique supérieur",
		paleolitiqueSupFull: "Paléolithique supérieur | -40 000 à -10 000",
		paleolitiqueSupDesc: "Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		ya10000ya9600: "Un réchauffement climatique marque le début de l'Holocène, l'ère géologique actuelle. Cela sonne aussi la fin de la longue période appelée Paléolithique.",
		ya15000ya10000Title: "Le Magdalénien",
		ya15000ya10000: "Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		ya21000: "Phase la plus froide de la dernière glaciation.",
		ya30000: "Extinction des Néandertaliens en Europe.",
		ya34000ya32000: "L’Homo Sapiens, l’homme moderne, commence sa progression à partir de l’Est et atteint nos régions entre 34.000 et 32.000 av. J-C. Homo Sapiens et Homo Neandertalensis occupent des sites identiques.",
		ya40000Title: "L’homme de Spy",
		ya40000: "Dans une grotte située à Spy, des ossements découverts en 1886 permettent aux chercheurs d’identifier les parties d’un squelette qui  se rattache à la famille des Néandertaliens. Pour la première fois, les éléments rassemblés permettent de confirmer l’existence et l’ancienneté d’un type humain de morphologie différente de celle de l’homme actuel. Cette contribution majeure à la meilleure connaissance de l’histoire de notre espèce donne toute son importance à l’Homme de Spy qui aurait vécu, à Spy, il y a 40.000 ans, environ.",

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen Âge classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Accueil",

		/* thermalBaths */
		thermalBaths: "Thermes gallo-romains",
		thermalBathsTitle: "Thermes gallo-romains",
		thermalBathsTitleDeter: "les Thermes gallo-romains",
		thermalBathsSubtitle:
			"Les thermes, un lieu incontournable de la vie à l’époque romaine/dans le quotidien des romains !",
		thermalBathsSec1Title: "Furfooz romain",
		thermalBathsSec1Content:
			"En toute terre où les Romains s’installent, il est un lieu incontournable : les thermes. Furfooz ne déroge pas à la règle ! Et oui, au Bas-Empire, après la conquête romaine de l’Europe, le plateau de Hauterecenne devient un fortin très important occupé par des auxiliaires barbares au service de Rome. Il faut donc y construire des infrastructures…",
		thermalBathsSec2Title: "Comment ça fonctionne, des thermes ?",
		thermalBathsSec2Content:
			"D’une superficie modeste de 75 m², le bâtiment comprend tous les éléments traditionnels de ces établissements romains, à savoir : une salle au bain chaud (caldarium) et au bain tiède (tepidarium), ainsi qu’une salle au bain froid (frigidarium). La pièce principale est chauffée par %lhypocauste%l, tandis que le bain froid est situé à l’écart, en saillie sur une petite pièce quadrangulaire.",
		thermalBathsLegend1:
			"Plan et coupe des thermes romains selon J Breuer (1957)",
		thermalBathsEncart1Title: "Pourquoi donc un établissement thermal ici ?",
		thermalBathsEncart1Content:
			"Il plane bien des mystères autour de la présence de thermes sur le site de Furfooz : De quelle période sont-ils datés? Comment les romains ont-ils réussi à acheminer l’eau jusqu’à l’édifice? Comment expliquer l’isolement total en cet endroit de cet ensemble thermal du Haut-Empire ? Antérieur au IVe siècle, certains auteurs justifient son existence par la présence possible d’une villa Gallo-romaine à proximité, mais aucune %lprospection%l n’a jamais pu en localiser l’emplacement. En ce qui concerne l’acheminement de l’eau, les hypothèses archéologiques vont bon train. En l’absence de sources sur le plateau, le géologue Van de Poel envisage par exemple l’existence d’une conduite d’eau qui aurait alimenté les thermes au départ d’une source située à quelques 600 m delà, au Fond-des-Vaux. Les %larchéologues%l ont encore de nombreuses questions à élucider !",
		thermalBathsSec3Title: "Plus qu’un bain, un lieu social et civique !",
		thermalBathsSec3Content:
			"Si les thermes ont pour fonction première d’assurer l’hygiène de la cité, ces bains publics sont aussi un haut lieu de sociabilité. Dans la société romaine, on y va tous les jours pour y rencontrer ses amis ou encore y faire du sport, et ce qu’importe son statut social ! Ils sont ouverts à tous, sans distinction de classe sociale, aux hommes comme aux femmes (dans des parties ou à des heures différentes).",
		thermalBathsEncart2Title:
			"L’archéologie expérimentale, une aventure scientifique !",
		thermalBathsEncart2Content:
			"Ils sont passionnés ces archéologues ! D’abord exploré en 1876-1877 par Alfred Bequet de la Société archéologique de Namur, il faut attendre le XXe siècle, de 1956 à 1958, pour qu’on entreprenne une reconstruction fidèle et grandeur nature des thermes romains. Grande aventure scientifique, l’archéologie expérimentale permet de reconstituer ces édifices disparus, de la façon la plus rigoureuse possible, et ainsi rendre accessible la pratique et l’Histoire à un large public. Un bel exemple de pédagogie !",
		thermalBathsSec4Title:
			"Quand l’horizon du Moyen Âge sonne le glas des thermes gallo-romains",
		thermalBathsSec4Content:
			"Si les thermes sont parfaitement visibles grâce à leurs reconstructions, l’histoire du site est bien plus complexe. En effet, à l’orée du Moyen Âge, le plateau de Hauterecenne est progressivement abandonné par le camp romain. Vers le dernier tiers du IVe siècle, le site des thermes va alors prendre une nouvelle fonction, celle d’une petite %lnécropole%l, une fois le bâtiment détruit.",
		thermalBathsLegend2: "Dessin des sépultures de la nécropole par A.Bequet",
		thermalBathsEncart3Title:
			"Bâtir une nécropole sur les ruines de thermes gallo-romains",
		thermalBathsEncart3Content:
			"Pour enterrer ses morts, la communauté occupant le site fortifié à la fin du IVe siècle jette son dévolu sur l’espace thermal, devenu terrain vague. La surface nécessaire pour y enfouir les défunts n’est pas très grande puisqu’on compte environ vingt-cinq sépultures fouillées. Les tombes sont creusées à même le sol, sans fondations ou infrastructure. L’étude de cette nécropole tardive dit beaucoup de choses de ses habitants, notamment la découverte de nombreux objets. Car oui, les haches laissent penser que la population de Furfooz seraient de nouveaux arrivants barbares, mais les beaux ceinturons enterrés appartiennent plus à la culture militaire romaine tardive. D’autres trouvailles compliquent l’identification certaine de ce peuple. Quoi qu’il en soit, c’est un beau mélange de culture à Furfooz !",
		thermalBathsSec5Title:
			"Actualité de l’archéologie, le site à nouveau fouillé !",
		thermalBathsSec5Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Hauterecenne */
		hauterecenne: "Plateau fortifié de Hauterecenne",
		hauterecenneTitle: "Plateau fortifié de Hauterecenne",
		hauterecenneTitleDeter: "le Plateau fortifié de Hauterecenne",
		hauterecenneSubtitle: "Une forteresse, plusieurs vies !",
		hauterecenneQuote1:
			"“L’homme, à toutes les époques, s’est établi dans cette localité [Furfooz] suivant les diverses conditions de son temps.”",
		hauterecenneQuoteAuthor1:
			"Edouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title:
			"Les traces de fortifications scrutées à la loupe tardivement",
		hauterecenneSec1Content:
			"Le champ de recherche préféré à Furfooz, c’est la paléontologie. Et pourtant, il existe d’autres traces de la présence humaine bien plus tardives et qui méritent une pleine attention ! Le plateau de Hauterecenne, au cœur de cette activité humaine du %lBas-Empire%l et du Haut Moyen Âge, est fouillé marginalement au XIXe siècle, mais il faut attendre les années 1930, puis 1980 pour qu’il fasse l’objet d’études approfondies.\nEn route pour découvrir tous ses secrets !",
		hauterecenneSec2Title:
			"Hauterecenne, un plateau stratégique pour l’installation… d’une garnison romaine !",
		hauterecenneSec2Content:
			"En étudiant les traces des anciens remparts, les archéologues ont mis au jour la présence d’une garnison romaine du Bas-Empire sur le site de Furfooz. Mais pourquoi s’installer ici ? Pour sa situation stratégique bien sûr ! Le plateau de Hauterecenne est un haut promontoire dominant la rivière, qui dispose d’une protection naturelle de ce côté mais aussi sur son flanc où le Ry des Vaux a lui-même creusé une petite vallée escarpée. Ainsi, situé au-dessus d’une falaise abrupte surplombant la Lesse de 60 mètres, il est très facile de fortifier le site et de se parer contre toute attaque ennemie ! Le plateau porte encore les traces bien conservées d’un certain nombre de structures anciennes et d’éléments de fortifications de périodes différentes. Les archéologues vous racontent.",
		hauterecenneLegend1:
			"Illustration de la construction d’un mur de barrage par Benoît Clarys",
		hauterecenneEncart1Title:
			"Les archéologues étudient ces lieux stratégiques",
		hauterecenneEncart1Content:
			"« Depuis le milieu du XIXe siècle, on y a conduit plusieurs campagnes de fouilles et le grand mérite de Raymond Brulet est d’avoir repris l’étude du site [...]. Le site a été ainsi occupé depuis l’époque romaine jusqu’au XIIIe siècle avec des temps forts au milieu du IIIe siècle et au début du Ve siècle. » Raymond Brulet, La fortification de Hauterecenne à Furfooz, (publication d'histoire de l'Art et d'Archéologie de l'Université catholique de Louvain XIII), 1978",
		hauterecenneSec3Title: "Puis d’une petite forteresse médiévale !",
		hauterecenneSec3Content:
			"Raymond Brulet nous met sur la piste… La forteresse aurait donc évolué au fil du temps ? Et oui, après la chute de l’Empire romain et le départ de ses troupes, le site se fortifie épisodiquement. Nous n’avons que peu d’informations quant aux habitants qui s’y installèrent, en revanche la présence d’une maison forte et l’ajout de remparts suggèrent l’installation d’un petit seigneur régional. À la fin du XIIIe siècle, le site perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		hauterecenneEncart2Title: "Savez-vous ce qu’est une maison forte ?",
		hauterecenneEncart2Content:
			"La maison forte est l’ancêtre des châteaux forts. De taille modeste, il n’est possible d’y pénétrer que par le 1er étage. Ainsi, si la maison est attaquée, l’escalier peut être retiré et les occupants protégés !",
		hauterecenneLegend2:
			"Mur de barrage sur le site du Katzenberg dans le massif de l’Eifel",
		hauterecenneSec4Title:
			"Une superposition d’époques, comment faire pour les distinguer ?",
		hauterecenneSec4Content:
			"Pour retracer l’histoire et l’évolution de l’occupation humaine sur un site, l’archéologue outillé, mais s’entoure de spécialistes. Dans le cas du plateau fortifié, il faut pouvoir distinguer les époques, alors qu’elles se superposent. On travaille alors sur une chronologie relative et on fait appel à des comparaisons site par site. Ce long cheminement a permis de faire une découverte importante : la présence de deux murs de barrage.",
		hauterecenneSec5Title: "L’affaire des deux murs de barrage",
		hauterecenneSec5Content:
			"Et oui, les différentes époques sont visibles sur les murs ! Le premier mur de barrage, le plus extérieur, date du début du Bas-Empire romain. Très étroit et construit selon la même technique que les thermes, cela laisse à penser qu’il devait être muni soit d’un talus de terre appuyé contre lui, soit d’un parapet en bois, ancré dans le mur. Pour une raison que l’on ignore, un second mur de barrage a été élevé ensuite. Il bénéficie d’une large porte charretière et son épaisseur est cette fois plus commune. Il remonte sans doute au Ve siècle. Lors de la dernière mise en défense du site, à l’époque du Moyen Âge classique, ce mur a été bouché, puis surélevé. Son système de circulation au sommet de l’infrastructure repose sur un série de piliers à arcatures.",
		hauterecenneSec6Title: "Et si on fouillait encore ce lieu ?",
		hauterecenneSec6Content:
			"En 2024, une nouvelle campagne de fouilles est organisée pour, encore et toujours, en apprendre davantage sur nos ancêtres, leurs modes de vie et leurs infrastructures. Quelles en seront les découvertes ? Seuls les archéologues pourront nous le dire !  → Ouvrir sur les fouilles 2024, à ajuster en fonction du résultat des fouilles.",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsTitleDeter: "le Trou des Nutons",
		nutonsSubtitle: "Entre découvertes paléontologiques et légende populaire",
		nutonsSec1Title: "Le Trou des nutons, première grotte explorée",
		nutonsSec1Content:
			"En 1864, notre cher Edouard Dupont se lance en premier dans la fouille du trou des Nutons. S’il le choisit comme point de départ de son exploration, c’est parce qu’il présente toutes les caractéristiques idéales à l’implantation d’un campement magdalénien : bien abrité, logé à 40 m au-dessus du lit de la rivière donc proche d’une source d’eau… parfait pour cette population semi-nomade de chasseurs de rennes ou de chevaux !",
		nutonsSec2Title: "Quand l’étude des sols rencontre l’étude des Hommes",
		nutonsSec2Content:
			"Mais comment diable Edouard Dupont tire-t-il autant de conclusions au sujet d’une population si lointaine ? En effectuant ce l’on appelle un sondage en archéologie. Sur une toute petite partie de la grotte, l'équipe de Dupont retire les couches successives du sol, jusqu’à 8 m de profondeur pour les étudier. Ces couches sont composées de sédiments, c’est-à-dire de dépôts qui en disent beaucoup sur la période d’occupation et les modes de vie de nos ancêtres. Indéniablement, géologie et archéologie sont intimement liées.",
		nutonsLegend1: "Coupe longitudinale du Trou des Nutons, Édouard Dupont",
		nutonsSec3Title: "Des premières grandes découvertes",
		nutonsSec3Content:
			"Edouard Dupont ne s’arrête pas aux sondages ! Il fait dynamiter les stalagmites et les blocs de pierre. Sous ceux-ci, il fait de grandes découvertes : des débris  d’une vingtaine d’espèces animales, des couteaux et des flèches en silex, des pièces de monnaie de l’époque gallo-romaine et divers ustensiles postérieurs. Par contre, il n’y a pas la moindre trace d’ossements humains… Pourtant, tout portait à croire que la découverte de crânes était possible !",
		nutonsSec4Title: "Le %lpaléontologue%l écoute les légendes populaires",
		nutonsSec4Content:
			"Et oui ! Si Edouard Dupont fait le choix de ce trou pour commencer son exploration, c’est aussi car une légende lui est associée… Selon la croyance populaire, les Nutons étaient des nains à l’aspect vieux et chétif, mais actifs, très malicieux et fort habiles de leurs mains. Ils vivaient cachés dans les rochers, les cavernes et les ruines. Pendant la nuit, pour un peu de nourriture et quelques friandises déposées à l’entrée de leurs  gîtes, ces êtres surnaturels réparaient les ustensiles, les objets mobiliers, raccommodaient des haillons et blanchissaient le linge des villageoises. À la belle saison, ils sortaient en nombre pour se livrer à de joyeuses danses dans les prés. Taquins, ils pouvaient être très susceptibles et se vengeaient de ceux qui les bernaient ou cherchaient à découvrir leurs secrets.",
		nutonsEncart1Title: "Une explication scientifique de la légende ?",
		nutonsEncart1Content:
			"Edouard Dupont tente d’expliquer la petite taille des populations de Furfooz : « Les hommes de Grenelle, et surtout ceux de Furfooz, étaient de petite taille. Les premiers alignaient encore une moyenne d’1m62, mais les seconds descendaient à 1m53. C’est presque exactement la taille moyenne des Lapons. Toutefois, cette stature réduite n’excluait ni la vigueur ni l’agilité nécessaire aux populations sauvages. Les os des membres et du tronc sont robustes, et les saillies, les dépressions de leur surface, accusent un développement musculaire très prononcé. À part cette robusticité générale, supérieure à ce que l’on rencontre habituellement, les squelettes des hommes de Furfooz et de Grenelle ressemblent fort à celui des hommes d’aujourd’hui » (Edouard Dupont, « Sur les crânes de Furfooz », Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"Aussi mystérieux qu’emblématique, le Trou des Nutons a toujours de quoi vous surprendre ! Continuez votre exploration de la réserve pour découvrir bien d’autres secrets.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalTitleDeter: "le Trou du Frontal",
		frontalSubtitle: "Entre habitat et sépulture",
		frontalSec1Title:
			"S’il est appelé Trou du Frontal, c’est grâce à une découverte !",
		frontalSec1Subtitle:
			"À votre avis, pourquoi ce nom ? Tout simplement car notre ami Edouard Dupont, en fouillant le trou en 1864, met la main dès les premiers coups de pioche sur un os frontal appartenant à un adolescent. Cette grande découverte est un événement dans le monde de la recherche et est annonciatrice de bien d’autres…",
		frontalSec2Title:
			"la découverte de deux crânes très différents, une énigme à élucider",
		frontalSec2Subtitle:
			"Le 10 décembre de la même année, le paléontologue dégage, en présence d’invités de marque, deux crânes humains intacts conservés dans un ossuaire. Cela ouvre un champ d’hypothèses immense quant à la période d’occupation de ces grottes, mais aussi aux modes de vie de nos ancêtres.",
		frontalLegend1:
			"Photographie des fouilles du Trou du Frontal en 1864 in. Atlas du Karst Wallon",
		frontalEncart1Title: "Récit de fouilles par Dupont",
		frontalEncart1Content:
			"“Cette petite caverne, tant par ses données géologiques que par les lumières qu’elle a répandues sur l’ethnographie ancienne, est la plus importante qui ait encore été fouillée dans notre pays. Il est certain que si l’explorateur eût souvent vu ses recherches couronnées d’un succès aussi complet, les laborieux travaux, entrepris dans notre région, eussent été fort abrégés, et il resterait aujourd’hui bien peu de chose à dévoiler sur les habitants de la Belgique à l’époque quaternaire. [...] Nous examinerons plus loin les données que les restes des repas dans l’abri extérieur et l’amas d’ossements humains nous fournissent sur les coutumes funéraires de l’antique peuplade.” \n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Se tromper fait partie de la recherche paléontologique !",
		frontalSec3Subtitle:
			"En étudiant ces ossements, Dupont les date à tort de l’époque des chasseurs de rennes. Mais être paléontologue c’est aussi se tromper ! Des études plus approfondies sur ces crânes et de l’ensemble des matériaux découverts sur le site ont mis au jour une confusion de la part de Dupont entre des traces de Magdaléniens, les fameux chasseurs, et celles de populations vivant au Néolithique.",
		frontalEncart2Title:
			" Quand la théorie de Darwin éclaire le monde paléontologique",
		frontalEncart2Content:
			"Les fouilles entreprises par Dupont sont considérées comme le premier argument anatomique en faveur des théories de  Darwin, retranscrites dans son ouvrage L’origine des espèces publié en 1859, quelques années avant ses découvertes. Grâce à ces crânes, il comprend l’évolution de l’espèce humaine en fonction des périodes d’occupation. Car oui, des Hommes de deux ères vivaient au trou du Frontal !",
		frontalSec4Title: "Des Hommes de deux ères : la vie, la mort",
		frontalSec4Subtitle:
			"Au Trou du Frontal, les fouilles archéologiques ont mis au jour à la fois des silex taillés datant de l’époque %lMagdalénienne%l, mais aussi de nombreux ossements, d’au moins 16 individus (hommes, femmes et enfants) situés nettement au-dessus de la couche du niveau magdalénien. Tout porte à croire qu’il s’agit-là de vestiges d’une sépulture datant du Néolithique moyen. Près de 10 000 ans les séparent et pourtant, tous ont choisi le trou du Frontal pour refuge !",
		frontalLegend2:
			"Exemple de plans d’une sépulture néolithique découverte à Furfooz, ici le Trou de la Mâchoire, d’après E.Van den Broeck",
		frontalSec5Title: "Vivre et mourir au Trou du Frontal",
		frontalSec5Subtitle:
			"L’amas d’ossements suggère un espace funéraire plutôt qu’un habitat. En effet, le procédé d’inhumation, c’est-à-dire d’enterrer ses morts s’étend à tout le territoire actuel de la Belgique pendant le Néolithique, il y a 7000 ans. Le Trou du Frontal en est un parfait exemple puisqu’il est même fermé par une dalle, comme on le ferait pour une tombe, ce qui confirme qu’il s’agit-là d’une sépulture secondaire datant du Néolithique moyen.",
		frontalSec6Title:
			"Du trou du Frontal au trou de la Mâchoire… il n’y a que 10 mètres à parcourir !",
		frontalSec6Subtitle:
			"La pratique funéraire est très présente sur le site de Furfooz. À quelques pas de trou du Frontal, le trou de la Mâchoire avait sans doute la même fonction puisqu’on y a découvert cinq squelettes dans un état remarquable. Et, nous le savons, étudier les morts c’est comprendre la vie !\nPassez à la buvette située non loin du trou du Frontal pour profiter pleinement des beautés de la nature.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleTitleDeter: "la Grotte de la Gatte d’Or",
		goldenPaddleSubtitle:
			"Une mine d’informations sur l’histoire de l’humanité !",
		goldenPaddleSec1Title:
			"Pourquoi diable étudier la roche pour en apprendre sur les Hommes ?",
		goldenPaddleSec1Subtitle:
			"“Les études de cette nature, qui puisent leurs éléments dans les sciences géologiques, sont donc du ressort exclusif des sciences naturelles. Par ces investigations, la géologie donne la main à l’histoire et l’aide à restaurer le passé le l’humanité.” Edouard Dupont, Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse,1872\n\nCes quelques mots de Edouard Dupont transmettent bien l’importance de l’étude de la roche, la sédimentation, dans la compréhension de l’histoire de l’humanité. On vous explique.",
		goldenPaddleLegend1:
			"Photographie de la Grotte de la Gatte d’or aujourd’hui ©Timescope",
		goldenPaddleSec2Title:
			"La sédimentation, une mine d’or pour les scientifiques !",
		goldenPaddleSec2Subtitle:
			"En décryptant les modifications du milieu naturel, celui que l’on appelle le géomorphologue nous renseigne sur les activités humaines qui l’ont façonné. En étudiant les sédiments, il détermine l’origine des dépôts (alluvions, ruissellements, remblais…) ou leur âge. Il observe aussi la succession des strates de sédiments visible grâce aux changements de couleur ou de texture des sols (argiles, sables, cailloux…). Chaque strate correspondant à une époque, la méthode stratigraphique permet de déterminer la nature et la chronologie des événements intervenus. À la Gatte d’Or, les couches sédimentaires sont très visibles et renseignent aussi bien sur la présence humaine que sur les origines même de Furfooz.",
		goldenPaddleSec3Title:
			"Des fouilles qui détériorent le patrimoine, cela arrive aussi…",
		goldenPaddleSec3Subtitle:
			"Au début du XXe siècle, le site de Furfooz n’est plus habité depuis longtemps mais est un haut lieu touristique payant. Hélas ! Après les nombreuses fouilles de Dupont, rien n’a été prévu pour protéger l’intérieur des grottes. L’afflux des vacanciers n’a fait qu’empirer les choses avec une détérioration à l’intérieur des  grottes dont celle de la Gatte d’or : bris de concrétions calcaires et altération de la teinte des  draperies suite à un mauvais emploi des bougies utilisées pour l’éclairage.",
		goldenPaddleEncart1Title: "La légende raconte…",
		goldenPaddleEncart1Content:
			"La légende du pays raconte qu’une gatte, une chèvre en wallon, vivrait dans les grottes de Furfooz et garderait un trésor… Cette histoire populaire remonte à une lointaine époque, où le tissu manquait et les banques n’existaient pas. Les gens confectionnaient de petits sacs en peau poilue et cachaient leurs deniers où ils pouvaient. Si bien que parfois, ils les oubliaient ou mourraient avec leur secret… Les malheureux sans mémoire font des heureux bien plus tard ! De cette légende naît la fameuse gatte toute d’or vêtue !",
		goldenPaddleSec4Title:
			"Aujourd’hui, la Gatte d’or est un spot naturel incontournable !",
		goldenPaddleSec4Subtitle:
			"Heureusement, l’association Ardenne & Gaume œuvre aujourd’hui pour la préservation de ce patrimoine. La présence humaine se fait quasi invisible et laisse place à un paysage naturel exceptionnel. La vue y est à couper le souffle !",

		/* Reuviau */
		reuviau: "Trou Reuviau",
		reuviauTitle: "Trou Reuviau",
		reuviauTitleDeter: "le Trou Reuviau",
		reuviauSubtitle: "Au Trou Reuviau, on se met à table !",
		reuviauSec1Title: "Reuviau, un trou discret mais riche en savoir",
		reuviauSec1Subtitle:
			"À la fin de votre promenade dans la réserve naturelle de Furfooz, n’oubliez pas de vous arrêter au trou Reuviau !  Modeste, il est pourtant très intéressant pour les paléontologues, notamment pour connaître mieux les pratiques alimentaires des Magdaléniens. On vous explique.",
		reuviauLegend1: "Photographie du Trou Reuviau aujourd’hui ©Timescope",
		reuviauEncart1Title: "Fouilles du Reuviau par E.Dupont",
		reuviauEncart1Content:
			"“Une autre petite caverne, le trou Reuviau, dans un ravin sur le chemin qui conduit de la Lesse à Furfooz, a fourni, dans les mêmes circonstances géologiques, des ossements de cheval, de renne, d’ours, etc., brisés de main d'homme.”\n\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "Le repas des Hommes à l’âge du renne",
		reuviauSec2Subtitle:
			"Edouard Dupont, puis bien d’autres paléontologues, ont découvert une série d’ossement de rennes et de chevaux qui portent à croire qu’ils étaient des mets de choix… cela a même donné le nom à l’âge du renne, pour dire ! Des silex mis au jour dans le trou en disent beaucoup sur la façon dont ces hommes dépeçaient les bêtes, aussi bien pour se nourrir que pour se vêtir.",
		reuviauEncart2Title: "Dupont défini l’âge de pierre",
		reuviauEncart2Content:
			"“La chasse leur fournissait leurs aliments et leurs grossiers vêtements ; l’os et surtout la pierre-à-feu leur donnaient des outils et des armes, sans qu’elles eussent pu arriver à produire des métaux ou seulement à en soupçonner l’existence. Aussi a-t-on désigné, sous le nom d’âges de pierre, ces époques lointaines et de longue durée.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "La chasse à la Préhistoire",
		reuviauSec3Subtitle:
			"Durant le paléolithique, le renne, passant en troupeaux dans les vallées, est le gibier de prédilection des hommes préhistoriques. De nombreuses traces de ces chasses ont été retrouvées dans les diverses cavernes de Furfooz. Animal grégaire et migrateur, le renne était sans doute aussi un animal facile à chasser, et à chasser en masse… Mais aussi à faire sécher ! L'ensemble de ces facteurs, étudiés par les paléontologues, nous en apprennent toujours un peu plus sur nos ancêtres !",
		reuviauSec4Title: "L’enquête dans les trous de Furfooz continue !",
		reuviauSec4Subtitle:
			"Vous arrivez à la fin de votre exploration mais, n’en doutez pas, vous n’avez découvert qu’une infime partie de la réserve naturelle de Furfooz ! N’hésitez pas à revenir pour en explorer ses moindres recoins, sa faune et sa flore luxuriantes, protégées depuis près de 70 ans !\nÀ bientôt.",
		reuviauLegend2:
			"Exemple de la flore - Seseli libanotis - observée à Furfooz ©Adélaïde Bernard",

		furfoozMystery: "Les mystères de Furfooz",
		archaeology: "Archéologie",
		doYouKnow: "Bon à savoir",
		anecdote: "Anecdote",
		edouardDupont: "L'anecdote Dupont",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paléontologue",
		palaeontologistDesc:
			"celui qui étudie l'apparition, l'évolution et l'extinction des espèces animales, au long d'une immense période qui remonte aux premières traces de vie sur Terre, il y a environ 3,5 milliards d'années.",
		archaeologist: "Archéologue",
		archaeologistDesc:
			"celui qui étudie les civilisations humaines du passé à partir de traces matérielles qui en subsistent (bâtiments, œuvres d’art, pièces de monnaie, tombes, manuscrits, objets de la vie quotidienne…).",
		geologist: "Géologue",
		geologistDesc:
			"celui qui étudie la composition et la structure des sols et roches de la croûte terrestre. Le but? Expliquer l’évolution de notre planète.",
		anthropologist: "Anthropologue",
		anthropologistDesc:
			"celui qui étudie l’homme, son comportement et son évolution. Sur les chantiers archéologiques, il analyse les squelettes afin de déterminer les conditions de vie et de mort de la personne. Il décrit également les sépultures (type de tombe, parures, offrandes, mobilier) afin de pouvoir reconstituer les gestes funéraires effectués par les proches du défunt.",
		sediment: "Sédiment",
		sedimentDesc:
			"dépôt qui se forme à partir de l’altération ou de la désagrégation de roches anciennes.",
		experimentalArchaeology: "Archéologie expérimentale",
		experimentalArchaeologyDesc:
			"Discipline de l’archéologie, cette discipline consiste à restituer, grâce à l’expérimentation, des bâtiments ou objets selon les techniques de construction et de fabrication d’époque.",
		necropolis: "Nécropole",
		necropolisDesc:
			"Equivalent d’un cimetière à l’Antiquité, révélé par l’archéologie. Les sépultures peuvent être situées dans des excavations souterraines ou être creusées dans le sol.",
		hypocaust: "Hypocauste",
		hypocaustDesc:
			"Local souterrain renfermant un système destiné à chauffer les bains, les salles des thermes ou encore des pièces à vivre.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"Ensemble des théories biologiques de Darwin et de ses disciples selon lesquels la sélection naturelle est le facteur essentiel de la transformation des espèces vivantes.",
		prospecting: "Prospection (archéologie)",
		prospectingDesc:
			"La prospection consiste à observer et étudier un site archéologique sans creuser dans le sol. Elle peut se faire à pied (en ramassant des objets éparpillés à la surface), depuis un avion (pour voir apparaître des anomalies dans le relief) ou encore avec des appareils radars qui donneront une indication sur d'éventuels bâtiments enfouis.",
		ossuary: "Ossuaire",
		ossuaryDesc:
			"Amas d’ossements. Cela peut aussi être un bâtiment où les ossements humains sont entassés.",

		palaeolithic: "Paléolithique supérieur",
		palaeolithicDesc:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		magdalenian: "Magdalénien",
		magdalenianDesc:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		neolithic: "Néolithique",
		neolithicDesc:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		antiquity: "Antiquité",
		antiquityDesc:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen  ge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		lateRoman: "Bas-Empire",
		lateRomanDesc:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen Âge.",
		highMiddleAges: "Haut Moyen Âge",
		highMiddleAgesDesc:
			"Période qui s’étend de la fin de l’Antiquité à la fin de la période carolingienne, soit à la fin du Xe siècle.",
		classicalMiddleAges: "Moyen Âge classique",
		classicalMiddleAgesDesc:
			"aussi appelée Moyen Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
	},
	{
		id: "en",
		flag: `url(${flagEn})`,
		lang: "English",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "In the footsteps of Humanity",
		start: "Start",
		/* Menu */
		timeline: "Timeline",
		map: "Map",
		glossary: "Glossary",
		/* Map */
		repereMap: "Find your way on the map",
		enjoyExp:
			"To get the most out of the Furfooz experience, we recommend that you share your location with us.",
		letsgo: "Here we go!",
		notNow: "I'm not on site",
		ok: "Ok",
		dotInactiv1: "This point is not yet active!",
		dotInactiv2: "You must first unlock the previous points.",
		restartExp: "Repeat the experience?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"An immersive game to discover Furfooz's treasures in the footsteps of Edmond, the famous Belgian archaeologist.",
		manyMarquage:
			"Go to the various markers along the route and find the secret code that will reveal exclusive content and lots of surprises!",
		playingInPlace: 'Play in "remote"',
		cutGeoloc:
			"Switch off geolocation and access content without answering riddles",
		resetExp: "Reset experiment",
		credits: "Credits",
		timescopeProject: "A Timescope project",
		creditsContent: "%Contents%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bPhotography%b Photographer",
		creditsTexts: "%bTexts %bOona Farell",
		creditsDesign: "%bMobile application design%b Felix Marquette",
		/* SecretCode */
		secretCode: "Secret code",
		validate: "Confirm",
		validate2: "Try again",
		badanswer: "It's not that...",
		bravo: "Bravo!",
		continue: "Continue",
		slideToDiscover: "Click to continue",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Image caption",
		backMap: "Back to map",
		knowMoreWith: "Find out more",
		backTime: "Going back in time",
		nowTime: "Back to the present",
		temporalFilter: "Time filter",

		t300ad: "300 A.D.",
		t1200ad: "1200 A.D.",
		t10000bc: "10 000 B.C.",

		/* Timeline */
		timelineTitle: "Timeline",
		scrollArrowsExpl:
			"Pssst... If you get tired of scrolling, use the arrows to move more quickly from one period to the next!",
		explanationTimeline:
			"The modern era represents only a small fraction of human history. Will you be able to retrace the footsteps of the first humans in Furfooz in this frieze where each pixel corresponds to 1 year?",
		timelineBottom: "You've reached the end! Well done :)",
		timelineBackFutur: "Back to the future",
		/* Glossary */
		explanationGlossaryLine1:
			"Here are some of the technical terms you may have come across so far.",
		explanationGlossaryLine2:
			"Discover other points of interest to complete your glossary!",
		unlockTo: "Unlocked at ",
		glossaryUnlock: "New term added to the glossary!",
		goGlossary: "New term added to the glossary!",
		/* Cookies */
		cookiesTitle: "This website uses cookies",
		cookiesContent:
			"We use cookies to save your progress in the game. By continuing you agree to our use of cookies.",
		cookiesAccept: "Accept",
		cookiesDenied: "Refuse",
		cookiesReadPrivacyPolicy: "Read our privacy policy.",
		/* PrivatePolicy */
		privatePolicyTitle: "Privacy policy",
		/* EndExp */
		welldone: "Great job!",
		allfound: "You've found all Furfooz's treasures.",
		allfound2: "Edouard Dupont would be proud of you.",
		shareExp: "Share your experience",
		shareExpWithFriends: "Share your experience with your friends!",
		link: "Link",
		x: "X",
		whatsapp: "Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "You've solved the first puzzle!",
		thermalBathsRiddleTitle: "Limestone",
		thermalBathsRiddleDesc:
			"Fantastic! In fact, the thermal baths were built using limestone. To bind the structure, the Romans used compact grey mortar and fine river gravel",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Congratulations on your second puzzle!",
		hauterecenneRiddleTitle: "Coin struck in the name of Constantine III.",
		hauterecenneRiddleDesc:
			"Well played! On the Hauterecenne plateau, archaeologists have discovered coins… spanning many centuries!",

		/* Nutons */
		nutonsRiddleDisc: "Another successful puzzle!",
		nutonsRiddleTitle:
			"Reindeer antler on which a bison or mammoth head is engraved by turning it over",
		nutonsRiddleDesc:
			"Well done! At the Nutons Hole, a reindeer antler was discovered on which a bison head is engraved. If you turn it over, you might see a mammoth!",

		/* Frontal */
		frontalRiddleDisc: "You've solved the fourth riddle!",
		frontalRiddleTitle: "Frontal bone of an adolescent",
		frontalRiddleDesc:
			"Congratulations! In 1864, Édouard Dupont discovered a frontal bone belonging to an adolescent, as well as two intact human skulls preserved in an ossuary.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Another riddle, keep it up!",
		goldenPaddleRiddleTitle:
			"End of a rock where sediments and geological layers can be seen",
		goldenPaddleRiddleDesc:
			"Congratulations!  This piece of rock is a valuable source of information for paleontologists. Inside, we can see sediment, a deposit that forms by the precipitation of materials suspended or dissolved in a liquid. Thanks to these, we can date the populations occupying the cave.",

		/* Reuviau */
		reuviauRiddleDisc: "That was the last riddle!",
		reuviauRiddleTitle: "Magdalenian chipped flint",
		reuviauRiddleDesc:
			"Congratulations ! You have reached the end of your journey. Flint is the emblematic tool of Prehistory. The Furfooz cavities revealed numerous series of Magdalenian and Middle Neolithic flints.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		blank: "",

		/* Epoque contemporaine */
		epoqueContemporaine: "Contemporary era",
		epoqueContemporaineFull: "Contemporary era | 1789 to the present day",
		y2024: "The Furfooz site is being excavated once again! This campaign is focused on the Hauterecenne plateau.",
		y1985y1986: "Archaeological excavation campaign in several prehistoric caves, under the direction of Professor M. Otte.",
		y1974y1976: "Excavation campaign of the Roman and medieval site under the direction of M.R. Brulet, restoration of the fortification walls and clearing of the base of the keep.",
		y1962: "Discovery of the underground Lesse route in Furfooz.",
		y1956: "End of the reconstruction work on the Roman baths in Furfooz, under the direction of M.J. Breuer",
		y1948: "Furfooz is classified as a nature reserve.",
		y1900y1902: "New excavations are undertaken at Furfooz by Rahir. He uncovered the Ossuary Shelter, Skull Hole and Jaw Hole.",
		y1872: "Following his research which started in the Meuse caves in 1864, Edouard Dupont published Prehistoric Times in Belgium: Humans during the Stone Ages in the Areas of Dinant-sur-Meuse. The work summarised his studies of the holes, condensing his entire journey and delivering his conclusions on the subject of Prehistory in Belgium.",
		y1868: "Edouard Dupont took over the management of the Royal Museum of Natural History of Belgium, a high position that he would carry out in an enlightened and authoritative manner for 41 years, expanding the spaces and collections.",
		y1865: "Edouard Dupont excavates the Reuviau Hole and Rosette Hole.",
		y1864: "Edouard Dupont was the first archaeologist commissioned by the Belgian government to carry out prehistoric excavations in the caves of the Meuse basin. Dupont began in the caves of the Lesse valley, in particular the Nutons Hole in Furfooz. These works are considered to be the first official cave excavations in the world.",
		y1841: "Birth of Édouard Dupont.",
		y1829y1833: "Doctor Ph.C. Schmerling excavated prehistoric caves in Wallonia for the first time. He was one of the first to provide proof that humans lived alongside extinct species such as the mammoth or the woolly rhinoceros.",
		y1830Title: "Independence of Belgium",
		y1830: "The occupation of Brussels by the Dutch army, between September 23 and 27, 1830, led to the creation of a revolutionary committee. This committee became a provisional government after the withdrawal of the Dutch. On October 4, 1830, the provisional government proclaimed the independence of the nine Belgian provinces. He instructed a commission to draw up a new constitution.",
		y1815Title: "Promulgation of the constitution of the United Netherlands",
		y1815: "In order to perfectly integrate the \"Belgian\" provinces with the \"Dutch\" provinces, King William of Orange had the Constitution, called Grondwet, of March 1814 and its 146 articles revised by a Dutch-Belgian commission. William I promulgated the text of the new Constitution on August 24, 181",
		y1793march26Title: "Second Austrian restoration",
		y1793march26: "As the French retreated north after a series of victories by the Austrian army, the Austrians restored the Prince-Bishop to his seat in Liège and entered Brussels on 26 March 1793. Welcomed by the population, they restored the Austrian Netherlands to its previous status and the Emperor appointed a new governor in the person of Archduke Charles.",
		y1793march01Title: "Annexation of the Walloon country to France",
		y1793march01: "The National Convention decides, from Paris, to annex to France all the former territories of the principality of Liège, the abbey principality of Stavelot-Malmedy and the provinces of the Austrian Netherlands. Danton, then commissioner of the republic designated in Liège and Namur, requested this annexation on January 31 during his famous speech on the theory of natural borders.",
		y1792y1793: "The Trou des Nutons in Furfooz was used by the French as a saltpeter factory, a substance used in the manufacture of gunpowder.",
		y1789y1791Title: "Liège revolution",
		y1789y1791: "This revolutionary movement of democratic inspiration echoed, in the principality of Liège, the start of the French Revolution. When the French took the Bastille on July 14, the Liège insurgents took the town hall on August 18, 1789. This caused the exile of the Prince-Bishop on August 26. The latter, temporarily reestablished by Austria in February 1791, enacted severe repression.",

		
		/* Période Moderne */
		periodeModerne: "Modern period",
		periodeModerneFull: "Modern period | 1492 to 1789",
		periodeModerneDesc: "Traditionally, historians start the beginning of the Modern period with the discovery of the Americas and its end coincides with that of the French Revolution. After the various revolutions, this period was renamed the Ancien Régime.",
		y1789y1799Title: "French Revolution",
		y1789y1799: "This period of political and social upheaval in France and its colonies would inspire the whole of Europe, including Belgium. Traditionally, it is said to begin with the opening of the Estates General on May 5, 1789 and ends with the coup d'état of Napoleon Bonaparte on November 9, 1799 (18 Brumaire of the year VIII). The Revolution put an end to the Ancien Régime, in particular the absolute monarchy replaced by the constitutional monarchy (1789-1792), then by the First Republic.",
		y1776: "Declaration of Independence of the United States of America (13 colonies).",
		y1748Title: "Treaty of Aix-la-Chapelle",
		y1748: "Restitution by Louis XV of the Walloon country (Tournai, Mons, Charleroi, Namur) to the Netherlands of Empress Maria Theresa. The War of the Austrian Succession, which began in 1740, reached Wallonia when French troops seized Tournai, Mons, Charleroi and Namur in turn. Having set out to conquer the United Provinces, Louis XV ended up accepting a peace treaty in 1748, signed in Aix-la-Chapelle.\nThe country was returned to Empress Maria Theresa, weakened in comparison to Prussia: the Austrian Netherlands were reconstituted for a few years.",
		y1568y1648Title: "Eighty Years' War or Dutch Revolt",
		y1568y1648: "An armed uprising against the Spanish monarchy by part of the Seventeen Provinces of the Spanish Netherlands. Having formed around the Peasants' Revolt, demanding religious freedom for Protestantism, the revolt also took the form of a civil war between the seven northern provinces (current Netherlands), where the Reformation was well established, and the southern Netherlands (current Belgium, Luxembourg and Nord-Pas-de-Calais), Catholic and loyalist.",
		y1566: "Two hundred factories operate in Walloon territory, while the whole of France has only double that number. The Walloon region established itself as one of the densest steel-producing regions in Western Europe.",
		sXVIsXVIII: "The Walloon provinces came under the control of the Netherlands ruled by the Habsburgs, of Spain, first, until 1714, then of Austria, until 1794.",
		y1566: "This is the oldest mention of the word \"Walloon\" found by Albert Henry in the Memoirs of the chronicler Jean de Haynin. The term Walloon then became generally used for a group that brought together a Romance language bloc and a Thiois language bloc in the same political framework.",
		y1517: "Birth of Protestantism and the Lutheran Reformation",

		/* Moyen Âge */
		moyenAge: "Middle Ages",
		moyenAgeFull: "Middle Ages | 476 AD to the 15th century",
		moyenAgeDesc: "Period beginning in the 5th century AD with the collapse and political and cultural fragmentation of the Roman Empire and ending towards the end of the 15th century, the time of the rise of the Italian Renaissance, the birth of modern nations and the discovery of new continents.",
		y1492:"Discovery of America. Early modern period.",
		y1454:"Invention of printing.",
		sXIII:"The Hauterecenne site loses its strategic importance and is gradually abandoned by humans",
		y1000y1100: "Development of stone castles for defensive purposes.",
		sXIsXIIITitle: "High Middle Ages",
		sXIsXIII: "Also called the Central Middle Ages, this period between the 11th and 13th centuries AD is characterised by a fragmentation of authority, the organisation of the feudal system and the development of castle architecture.",
		y476sXTitle: "Early Middle Ages",
		y476sX: "Period extending from the end of Antiquity at the end of the Carolingian period, that is to say to the end of the 10th century. It was at the beginning of this period that the Hauterecenne fortress was permanently occupied.",
		sV: "Spread of Christianity in Wallonia.",
		sVsVIIITitle: "Merovingian period",
		sVsVIII: "The Merovingians were a dynasty that ruled from the 5th to the middle of the 8th century over a large part of present-day France and Belgium, as well as parts of Germany, the Netherlands and Switzerland.",

		/* Antiquité */
		antiquite: "Antiquity",
		antiquiteFull: "Antiquity | 3,500 BC to 476 AD",
		antiquiteDesc: "Period extending from the invention of writing to the early Middle Ages, that is to say the period between 3,500 BC and 476 AD (date of the fall of the Western Roman Empire).",
		y303Title: "Administrative reorganissation of the empire by Diocletian",
		y303: "When the Emperor Diocletian (284-305) carried out a general reorganisation of the Empire, he distinguished between, in the province Belgica, Upper or First Belgium (capital Trier), Lower or Second Belgium (capital Reims). As for Lower Germania, it is now renamed Germania Secunda: this is where the civitas Tungrorum was located, crossed through the centre by the Meuse, between Dinant and its mouth. Diocletian's reform seems to have been in effect since 303 AD.",
		sIIIsIV: "Continued occupation of the Hauterecenne fortress.",
		sIIIy476Title: "Late Empire",
		sIIIy476: "Period of the Roman Empire extending from the end of the 3rd century to the fall of the Western Empire in 476 AD. This period illustrates the transition between Antiquity and the Middle Ages.",
		ya52Title: "Caesar conquers Gaul",
		ya52: "This was the beginning of romanisation. Thus, the inhabitants of Wallonia became Gallo-Roman, abandoned their Celtic languages in favour of Latin, integrated the culture and, gradually, acquired Roman citizenship.",
		ya450Title: "The Celts settle in Wallonia",
		ya450: "They were to form the basis of a rich civilisation, particularly in terms of industry (metallurgy) and quality craftsmanship. From this time on, the Germanic people used the term Walha to name these Celts living on the southern and western borders of their regions. The Celts distinguished themselves by their metallurgy, practicing forging and quality craftsmanship within real commercial networks.",
		ya750ya27Title: "Iron Age",
		ya750ya27: "A period when societies are considerably structured. The steel industry was developing in our regions. The Hallstatt period (or Early Iron Age) lasted from 750 to 480 BC and was characterised by a strengthening of power. At the same time, wealth was concentrated in the hands of a few individuals and society consequently became increasingly hierarchical, with numerous fortifications being erected. The La Tène period (or Second Iron Age), located between 480 BC and the reign of Augustus, was marked by a fragmentation of power, with tribes weaving a network of alliances and clientele among themselves.",
		ya2300ya750Title: "Bronze Age",
		ya2300ya750: "Sedentary since the Neolithic period, the people of the Bronze Age perfected their agricultural techniques and became virtuosos in bronze metallurgy. This is also the time of the birth of writing in Mesopotamia.",

		/* Neolithique */
		neolithique: "Neolithic",
		neolithiqueFull: "Neolithic | 6,500 to 2,100 BC",
		neolithiqueDesc: "A period marking the end of prehistory, between 6,500 and 2,100 BC, when humans became sedentary and developed agriculture.",
		ya4000Title: "Middle Neolithic",
		ya4000: "Development period for the burial process, that is to say of burying one's dead, throughout Belgium. Wallonia experienced its prehistoric golden age, marked in particular by the erection of fortifications and the “industrial” commercialisation of flint.",
		ya6000Title: "Upper Neolithic and the first Neolithic traces in Wallonia",
		ya6000: "The Neolithisation movement of Northwestern Europe began around 10,000 years ago in the Middle East. It spread westwards to the Balkans before splitting into two: one part developed in the South of France and Spain and the other in the Balkans and the Middle Danube basin, where the so-called \"ribboned\" civilisation was formed, in reference to the ribbon decorations on pottery remains. The Danube then reached northern Europe as far as our regions. The first farmers established “in Walloon country” therefore came from these Danube plains.",

		/* Mésolithique */
		mesolithique: "Mesolithic",
		mesolithiqueFull: "Mesolithic | 9600 to 6000 BC",
		mesolithiqueDesc: "Also called the Epipaleolithic, this intermediate period is marked by a sudden global warming that we date from the start of our current interglacial period. Paleolithic humans adapted to this and adopted a semi-nomadic lifestyle. Seasonal movements of hunter-gatherers became less frequent. They nevertheless maintained a way of life based on hunting, fishing and gathering, which ended with the arrival of Neolithic farmer-breeders, around 6,000 BC in France.",
		ya5500ya2000Title: "Dunkirk marine expansion",
		ya5500ya2000: "Oceanic expansion of the North Sea, i.e. the advance of the coastline following the rise in sea level, which gradually invaded the coastal plains, particularly Flemish. The waters then occupied the Flemish maritime plain, depositing sand 25 to 30 metres thick. During periods of retreat, rivers flowing down from the hills brought clayey silt on which vegetation settled.",

		/* Paléolithique supérieur */
		paleolitiqueSup: "Upper Paleolithic",
		paleolitiqueSupFull: "Upper Paleolithic | -40,000 to -10,000",
		paleolitiqueSupDesc: "Third and last phase of the Paleolithic, this period covers 40,000 BC to 10,000 BC. It is a period marked by tool diversification and the appearance of art. This is a period of major transition in human evolution, since, following the disappearance of the Neanderthals around 30,000 BC, Homo sapiens became the only species representing the Homo genus. Its ancient Greek etymology means \"the age of the old stone\", as opposed to \"the age of the new stone\", designating the Neolithic that followed.",
		ya10000ya9600: "A warming climate marked the beginning of the Holocene, the current geological era. This also marked the end of the long period called the Paleolithic.",
		ya15000ya10000Title: "The Magdalenian",
		ya15000ya10000: "Also called the Reindeer Age by Edouard Dupont, the Magdalenian was the most recent culture of the Upper Paleolithic. Developing in our regions between 15,000 and 10,000 years before our time, at the end of the last ice age, we see the rise of art and bone working.",
		ya21000: "Coldest phase of the last ice age.",
		ya30000: "Extinction of Neanderthals in Europe",
		ya34000ya32000: "Homo Sapiens, the modern human, began its progression from the East and reached our regions between 34,000 and 32,000 BC. Homo Sapiens and Homo Neandertalensis occupied identical sites.",
		ya40000Title: "The Man from Spy",
		ya40000: "In a cave located in Spy in Wallonia, bones discovered in 1886 allowed researchers to identify parts of a skeleton that belonged to the Neanderthal family. For the first time, the elements collected made it possible to confirm the existence and antiquity of a type of human with a morphology different from that of modern mankind. This major contribution to the better knowledge of the history of our species gives all its importance to the Spy Man who would have lived in the region approximately 40,000 years ago.",

		/* Modern */
		timelineModern: "Modern period",
		today: "Today",
		littleSticksOnSide:
			"See those little lines on the left-hand side of the screen? Each line marks 10 years on this timeline, going back to the very first traces of man.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen Âge classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parking",

		/* accueil */
		accueil: "Home",

		/* thermalBaths */
		thermalBaths: "Gallo-Roman baths",
		thermalBathsTitle: "Gallo-Roman baths",
		thermalBathsTitleDeter: "Gallo-Roman baths",
		thermalBathsSubtitle:
			"The thermal baths, an essential location in Roman life",
		thermalBathsSec1Title: "Roman Furfooz",
		thermalBathsSec1Content:
			"In every land where the Romans settled, there was one key location: the thermal baths. Furfooz was no exception to the rule! And yes, in the Late Empire, the Hauterecenne plateau became a very important fortress occupied by a militia that served Rome. So a little infrastructure building was required…",
		thermalBathsSec2Title: "How do thermal baths work?",
		thermalBathsSec2Content:
			"With a modest surface area of 75 m2, the building included all the traditional elements of these Roman establishments, namely: a heated room with a hot bath (caldarium) and a warm bath (tepidarium), as well as a room with a cold bath (frigidarium). The main room was heated by hypocaust, while the cold wing was situated separately, projecting into a small quadrangular room which also served as a changing room.",
		thermalBathsLegend1:
			"Plan et coupe des thermes romains selon J Breuer (1957)",
		thermalBathsEncart1Title: "Why place a thermal building here?",
		thermalBathsEncart1Content:
			"Many questions remain about the Furfooz thermal baths: from what period do they date? How did the Romans manage to bring water in to the building? If the baths were not linked to a fortress, their presence away from any ancient habitat would be difficult to explain, especially since no Roman villa has been discovered nearby. The use of these baths by the occupants of the plateau is the most probable hypothesis, this is also the case in many comparable military sites dating back to the beginning of the Late Empire. As for the water supply, there is a lot of speculation. In the absence of a spring on the plateau, the geologist Van de Poel suggests, for example, the existence of a water pipe which would have supplied the thermal baths from a source located some 600m away, at Fond-des-Vaux. Archaeologists still have many questions to answer!",
		thermalBathsSec3Title: "More than just a bath, a social and civic place!",
		thermalBathsSec3Content:
			"While the primary function of the thermal baths was to ensure the hygiene of the city, these public baths were also a high place of sociability. In Roman society, people went to them daily to meet with friends or to play sports, regardless of their social status! They were open to everyone, regardless of social class, including both men and women (in different parts or at different times).",
		thermalBathsEncart2Title:
			"Experimental archaeology, a scientific adventure!",
		thermalBathsEncart2Content:
			"Archaeologists are passionate people! First explored in 1876-1877 by Alfred Bequet of the Namur Archaeological Society, it was not until the 1930s that new investigations were carried out at the Furfooz thermal baths by Jacques Breuer.  From 1956 to 1958, with a view to creating a faithful and life-size reconstruction of the Roman baths, a new adventure in experimental archaeology made it possible to reconstruct this lost building, in the most rigorous way possible, and thus to make their practice and history accessible to a wide audience. A great way to teach!",
		thermalBathsSec4Title:
			"When the coming Middle Ages sounded the death knell of the Gallo-Roman baths",
		thermalBathsSec4Content:
			"Although the thermal baths are perfectly clear to see now, thanks to their reconstruction, the history of the site is much more complex. Indeed, at the dawn of the Middle Ages, the Hauterecenne plateau was temporarily abandoned by the occupants of the Roman fortress. Towards the last third of the 4th century, the baths were dismantled and the site became home to a small necropolis.",

		thermalBathsLegend2: "Dessin des sépultures de la nécropole par A.Bequet",
		thermalBathsEncart3Title:
			"Building a necropolis in the ruins of Gallo-Roman baths",
		thermalBathsEncart3Content:
			"To bury their dead, the new community occupying the fortified site at the end of the 4th century set its sights on the thermal area, which had become a wasteland. And nothing was wasted, as those materials were reused in the construction of walls on the plateau!\n\nThe area required to bury the deceased was not very large as only twenty-five excavated graves have been counted. The graves were dug into the ground directly, some of them had a coffin. The study of this late necropolis says a lot about its inhabitants, notably the discovery of numerous objects, known as grave goods. These items imply a privileged status of the deceased and testify to a militarised character of the corresponding group. It was wrongly thought that this was a Germanic immigrant community, but the tableware and the beautiful belts belonged to the late Roman military culture. During this period of transition, a certain mix of cultures is to be expected in Furfooz!",
		thermalBathsSec5Title: "Archaeology news, the site excavated again!",
		thermalBathsSec5Content:
			"In 2024, a new campaign of preventive excavations is to be organised to continue learning more about our ancestors, their lifestyles and their infrastructure. What will we discover? Only archaeologists will be able to tell us!",

		/* Hauterecenne */
		hauterecenne: "Fortified Hauterecenne plateau",
		hauterecenneTitle: "Fortified Hauterecenne plateau",
		hauterecenneTitleDeter: "the Fortified Hauterecenne plateau",
		hauterecenneSubtitle: "One fortress, many lives!",
		hauterecenneQuote1:
			"“Humanity, across all periods, have inhabited this location [Furfooz] depending on the various conditions of their time.”",
		hauterecenneQuoteAuthor1:
			"Édouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		hauterecenneSec1Title: "Fortification ruins examined closely",
		hauterecenneSec1Content:
			"Furfooz's most popular field of research is paleontology.  However, there are other traces of human presence from much later that deserve our full attention!  It was while excavating the Hauterecenne plateau that archaeologists discovered human activity from the Late Empire and the Early Middle Age periods. Partially excavated in the 19th century, it was not until the 1930s and then the 1980s that the plateau was subject to more in-depth studies. Well on the way to discovering its secrets!",
		hauterecenneSec2Title:
			"Hauterecenne, a strategic plateau for the installation… of a Roman garrison!",
		hauterecenneSec2Content:
			"By studying the traces of the ancient ramparts, archaeologists uncovered the presence of a Roman garrison from the Late Empire on the Furfooz site. But why settle here? For its strategic location of course! The Hauterecenne plateau is a high promontory overlooking the river and benefiting from natural protection on this side, but also on its other side, where the Ry des Vaux has carved out a small steep valley. Thus, located above a steep cliff overlooking the Lesse from 60 metres, it is very easy to fortify the site and protect against any enemy attack! The plateau still bears the well-preserved remains of a number of ancient structures and fortification elements from different periods. Archaeologists tell us all.",
		hauterecenneLegend1:
			"Illustration de la construction d’un mur de barrage par Benoît Clarys",
		hauterecenneEncart1Title: "Archaeologists study these strategic places",
		hauterecenneEncart1Content:
			"\"Since the middle of the 19th century, several excavation campaigns have been carried out and to Raymond Brulet's great credit, the site’s study was resumed […]. The site was thus occupied from the Roman era until the 13th century with peak periods in the middle of the 3rd and the beginning of the 5th centuries.\n\nRaymond Brulet, The fortification of Hauterecenne at Furfooz, (published by the history of Art and Archeology of the Catholic University of Louvain XIII), 1978.",
		hauterecenneSec3Title: "Then a small medieval fortress!",
		hauterecenneSec3Content:
			"Raymond Brulet puts us on the right track... So the fortress evolved over time? Indeed, after the fall of the Roman Empire and the departure of its troops, the site was fortified episodically. We have little information about the inhabitants who settled there, however the presence of a fortified house and the addition of ramparts suggest the installation of a small regional lord. At the end of the 13th century, the site lost its strategic importance and was gradually abandoned by mankind.",
		hauterecenneEncart2Title: "Do you know what a fortified house is?",
		hauterecenneEncart2Content:
			"A fortified house is the ancestor of fortified castles. Modest in size, it can only be entered via the first floor. So if the house is attacked, the staircase can be removed and the occupants protected!",
		hauterecenneLegend2:
			"Mur de barrage sur le site du Katzenberg dans le massif de l’Eifel",
		hauterecenneSec4Title: "A superposition of eras, how to tell them apart?",
		hauterecenneSec4Content:
			"To trace the history and evolution of human occupation on a site, the well-equipped archaeologist connects with a range of specialists. In the case of the fortified plateau, it is necessary to be able to tell the time periods apart, even though they overlap.  Then work begins on a relative chronology and the use of site-by-site comparisons. This long journey allowed us to make an important discovery: the presence of two dam walls.",
		hauterecenneSec5Title: "The case of the two dam walls",
		hauterecenneSec5Content:
			"And yes, the different eras are clearly visible on the walls! The first, outermost, barrier wall dates from the beginning of the Late Roman Empire. Very narrow and built using the same technique as the baths, this suggests that it must have had an earth bank leaning against it or a wooden parapet, anchored in the wall. For some unknown reason, a second dam wall was subsequently built. It has a wide carriage door and its thickness is this time more typical. It most likely dates back to the 5th century. During the last defence of the site, in the High Middle Ages, this wall was filled in and then raised. Its pathway system at the top of the infrastructure is based on a series of arched pillars.",
		hauterecenneSec6Title: "What if we searched this place again?",
		hauterecenneSec6Content:
			"In 2024, a new excavation campaign was organised to continue learning more about our ancestors, their lifestyles and their infrastructures. What will we discover? Only archaeologists will be able to tell us!",

		/* Nutons */
		nutons: "Nutons Hole",
		nutonsTitle: "Nutons Hole",
		nutonsTitleDeter: "Nutons Hole",
		nutonsSubtitle: "between paleontological discoveries and popular legend",
		nutonsSec1Title: "Nutons Hole, the first cave explored",
		nutonsSec1Content:
			"In 1864, our dear Édouard Dupont was the first to embark on the excavation of the Nutons hole. If he chose it as the starting point for his exploration, it was because it had all the ideal characteristics for a Magdalenian camp: well sheltered, located 40 metres above the river bed and therefore close to a water source... perfect for this semi-nomadic population of reindeer and horse hunters!",
		nutonsSec2Title: "When soil studies meet people studies",
		nutonsSec2Content:
			"But how on earth does Edouard Dupont draw so many conclusions about such a distant population? By carrying out what is called in archaeology a survey. In a very small part of the cave, Dupont's team removed successive layers of soil, up to 8m deep, to study them. These layers are made of sediment, the deposits that tell us a lot about the time period under which it was occupied, and the lifestyles of our ancestors. Undeniably, geology and archaeology are closely linked.",
		nutonsLegend1: "Coupe longitudinale du Trou des Nutons, Édouard Dupont",
		nutonsSec3Title: "First major discoveries",
		nutonsSec3Content:
			"Édouard Dupont doesn't stop at just surveys! He had the stalagmites and stone boulders dynamited. Underneath these, he discovered some amazing things: remains of around twenty animal species, flint knives and arrows, coins from the Gallo-Roman period and various later utensils. On the other hand, there was not the slightest trace of human remains... However, the evidence suggested that the finding skulls was entirely possible!",
		nutonsSec4Title: "The %lpaleontologist%l listens to popular legends",
		nutonsSec4Content:
			"While Édouard Dupont chose this hole to begin his exploration, this was also because there is a legend associated with it... According to popular belief, the Nutons were dwarves with an old and wiry appearance, but active, very mischievous and very skilled with their hands. They lived hidden in rocks, caves and ruins. During the night, in return for a little food and a few treats left at the entrance to their lodgings, these supernatural beings repaired utensils, furniture, mended rags and washed the village women's linen. In the summer, they came out in large numbers to perform joyful dances in the meadows. While playful, they could also be very touchy and took revenge on those who tricked them or sought to discover their secrets.",
		nutonsEncart1Title: "A scientific explanation for the legend?",
		nutonsEncart1Content:
			"Édouard Dupont tries to explain the small size of the Furfooz populations: “The men of Grenelle, and especially those of Furfooz, were small in stature. The first lot found had an average height of 1m62, but the second lot dropped to 1m53. This is almost exactly the average height of the Lapps. However, this reduced stature did not rule out the vigour and agility necessary for wild populations. The bones of the limbs and trunk were robust, and the projections and depressions on their surfaces showed very pronounced muscular development. Apart from this general robustness, superior to what is usually encountered, the skeletons of the men of Furfooz and Grenelle strongly resemble those of today's people.\n\nÉdouard Dupont, “Sur les crânes de Furfooz”, Compte-rendu du Congrès de Préhistoire, 1872. pp. 251-252).",
		nutonsQuote1:
			"As mysterious as it is emblematic, the Nutons Hole always has something in store to surprise you with! Continue exploring the reserve to discover many more secrets.",

		/* Frontal */
		frontal: "Frontal Hole",
		frontalTitle: "Frontal Hole",
		frontalTitleDeter: "Frontal Hole",
		frontalSubtitle: "Between habitat and burial ground",
		frontalSec1Title: "The name Frontal Hole comes from a great discovery!",
		frontalSec1Subtitle:
			"Why do you think this is its name? Quite simply because our friend Édouard Dupont, while excavating the hole in 1864, found, with the first blows of the pickaxe, a frontal bone belonging to an adolescent. This great discovery was a remarkable event in the world of research; although others like it were to follow.",
		frontalSec2Title:
			"The discovery of two very different skulls: an enigma to be solved",
		frontalSec2Subtitle:
			"On December 10 of the same year, the paleontologist unearthed, in the presence of some distinguished guests, two intact human skulls preserved in an ossuary. This opens up a huge field of hypotheses regarding the period of occupation of these caves, but also the lifestyles of our ancestors.",
		frontalLegend1:
			"Photographie des fouilles du Trou du Frontal en 1864 in. Atlas du Karst Wallon",
		frontalEncart1Title: "Dupont's account of the excavations",
		frontalEncart1Content:
			"“This small cavern, as much for its geological data and for the light it has shed on ancient ethnography, is the most important that has yet been excavated in our country. It is certain that if the explorer had as often seen their research crowned with such complete success, the laborious work undertaken in our region would have been greatly shortened, and there would remain today very little to reveal about the inhabitants of Belgium in the Quaternary period. [...] We will examine later the data that the remains of the meals in the outdoor shelter and the pile of human bones provide us with on the funeral customs of the ancient people.” Édouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		frontalSec3Title: "Making mistakes is part of the research!",
		frontalSec3Subtitle:
			"In his initial study of these bones, Dupont wrongly dated them to the time of the reindeer hunters. But being a paleontologist also means often being wrong! Further study of these skulls and of all the materials discovered at Frontal Hole reveals a confusion on Dupont's part between traces of Magdalenians, the famous hunters, and those of populations living in the Neolithic period.",
		frontalEncart2Title:
			"When Darwin's theory sheds light on the paleontological world",
		frontalEncart2Content:
			"The excavations undertaken by Dupont are considered to be the first anatomical argument supporting Darwin's theories, transcribed a few years before these discoveries, in 1859, in his work On The Origin of Species. Thanks to these skulls, Dupont understood the evolution of the human species through periods of occupation. Because yes, people from two eras lived at the Frontal Hole",
		frontalSec4Title: "People of two eras: life, death",
		frontalSec4Subtitle:
			"At the Frontal Hole, archaeological excavations have uncovered both cut flints dating from the Magdalenian period, and also numerous bones, from at least sixteen individuals, men, women and children, located well above the Magdalenian level layer. Everything suggests that these are the remains of a burial site dating from the Middle Neolithic period. Almost 10,000 years separate them and yet, they all chose the Frontal Hole as their final resting place!",
		frontalLegend2:
			"Exemple de plans d’une sépulture néolithique découverte à Furfooz, ici le Trou de la Mâchoire, d’après E.Van den Broeck",
		frontalSec5Title: "Living and dying at Frontal Hole",
		frontalSec5Subtitle:
			"The bone pile suggests a burial site rather than a habitat. In fact, the burial process, that is to say the process of burying one's dead, extended to the entire territory of present-day Belgium during the Neolithic period, around 7,000 years ago. The Frontal Hole is a perfect example of this since it is even closed by a slab, as one would do for a tomb, which confirms that this is a secondary burial site dating from the Middle Neolithic.",
		frontalSec6Title:
			"From the Frontal Hole to the Jawbone hole… there are only 10 metres between them!",
		frontalSec6Subtitle:
			"Funeral practices were omnipresent at the Furfooz site. Just a few paces away from the Frontal Hole, the Jawbone Hole likely had the same function as five skeletons were discovered there, all in remarkable condition. And, as we know, studying death helps us to understand life!\n\nStopping at La Flobette, the refreshment bar located not far from the Frontal Hole, to fully enjoy the beauty of nature.",

		/* GoldenPaddle */
		goldenPaddle: "Golden Goat Cave",
		goldenPaddleTitle: "Golden Goat Cave",
		goldenPaddleTitleDeter: "Golden Goat Cave",
		goldenPaddleSubtitle: "A mine of information on the history of humanity!",
		goldenPaddleSec1Title:
			"Why on Earth would studying rocks help us learn about humans?",
		goldenPaddleSec1Subtitle:
			"“Studies of this nature, which draw their elements from the geological sciences, are therefore the exclusive domain of the natural sciences. Through these investigations, geology gives a hand to history and helps it to restore humanity’s past.”\n\nÉdouard Dupont, Prehistoric times in Belgium: Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872\n\nThese few words from Édouard Dupont reflect the importance of studying rocks, sedimentation, and in understanding the history of humanity. Let us explain.",
		goldenPaddleLegend1:
			"Photographie de la Grotte de la Gatte d’or aujourd’hui ©Timescope",
		goldenPaddleSec2Title: "Sedimentation, a gold mine for scientists!",
		goldenPaddleSec2Subtitle:
			"By deciphering changes in the natural environment, people known as geomorphologists tell us about the human activities that shaped it. By studying the sediment, they determine both the origin of the deposits (alluvium, runoff, embankments, etc.) and their age. They also observe the succession of sediment layers because each layer corresponds to an era. They are visible thanks to changes in the colour or texture of the soil (clay, sand, pebbles, etc.). The stratigraphic method makes it possible to determine the nature and chronology of events. At the Golden Goat, the sedimentary layers are very visible and provide information on both human presence and the origins of Furfooz.",
		goldenPaddleSec3Title: "Excavations that cause damage can also happen…",
		goldenPaddleSec3Subtitle:
			"At the beginning of the 20th century, the site of Furfooz had not been inhabited for a long time but was a major tourist attraction, that visitors had to pay for. Unfortunately! After Dupont's extensive excavations, nothing was done to protect the interior of the caves. The influx of holidaymakers only made things worse with deterioration inside the caves, particularly due to the improper use of candles used for lighting.",
		goldenPaddleEncart1Title: "Legend has it…",
		goldenPaddleEncart1Content:
			"The legend of the country has it that a gatte, or a goat in Walloon, lives in the caves of Furfooz and guards a treasure... This popular story goes back to a distant time, when fabric for bags was scarce and banks did not exist. People made small bags out of hair-covered skins and hid their money wherever they could. So much so that sometimes they forgot about them or died with their secret... Those unfortunate souls with memory-loss made people happy much later! From this legend was born the famous golden goat!",
		goldenPaddleSec4Title:
			"Today, the Golden Goat is an unmissable natural location!",
		goldenPaddleSec4Subtitle:
			"Fortunately, the Ardenne & Gaume association is now working to preserve this natural heritage. Human presence has become almost invisible and gives way to an exceptional natural landscape. The view is breathtaking! Moreover, the Furfooz reserve is closed for part of the year to allow nature to regenerate. Nature needs a holiday too!",

		/* Reuviau */
		reuviau: "Reuviau Hole",
		reuviauTitle: "Reuviau Hole",
		reuviauTitleDeter: "Reuviau Hole",
		reuviauSubtitle: "At Reuviau Hole, it’s dinner time!",
		reuviauSec1Title: "Reuviau, a discreet hole but one rich in knowledge",
		reuviauSec1Subtitle:
			"Near the end of your walk through the Furfooz nature reserve, don't forget to stop at the Reuviau hole!  Modest in size, it is nevertheless very interesting for paleontologists, particularly to better understand the dietary practices of the Magdalenians.",
		reuviauLegend1: "Photographie du Trou Reuviau aujourd’hui ©Timescope",
		reuviauEncart1Title: "Reuviau Excavations by Édouard Dupont",
		reuviauEncart1Content:
			"“Another small cave, the Reuviau hole, in a ravine on the path leading from the Lesse to Furfooz, offered, in the same geological circumstances, bones of horses, reindeer, bears, etc., broken by human hands.”\n\nÉdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "What people ate in the Reindeer Age",
		reuviauSec2Subtitle:
			'Édouard Dupont, and later many other paleontologists, discovered a series of reindeer and horse bones. Everything suggests that they were a delicacy for these cavemen... to the point that we call it the "Age of the Reindeer" in their honour!Flints unearthed in the hole tell us a lot about how these people prepared the animals, both for food and clothing.',
		reuviauEncart2Title: "Dupont defines the Stone Age",
		reuviauEncart2Content:
			"“Hunting provided them with their food and their coarse clothing; bone and especially flint gave them tools and weapons, while still being unable to produce metals or even suspect their existence. These distant and long-lasting periods have therefore been called the Stone Ages.”\nEdouard Dupont, Les temps préhistoriques en Belgique : l'homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "Prehistoric hunting",
		reuviauSec3Subtitle:
			"During the Paleolithic, reindeer, passing in herds through the valleys, were therefore the game of choice for prehistoric people. Many traces of these hunts have been found in the various caves of Furfooz. A gregarious and migratory animal, the reindeer was undoubtedly easy game to hunt en masse… But also to dry out! All of these factors, studied by paleontologists, teach us yet more about our ancestors.",
		reuviauSec4Title: "The investigation into the Furfooz holes continues!",
		reuviauSec4Subtitle:
			"You have reached the end of your exploration but, have no doubt, you have only discovered a tiny part of the Furfooz nature reserve! Come back soon to explore its every nook and cranny, its lush flora and fauna, protected now for almost 70 years!\nSee you soon!",
		reuviauLegend2:
			"Exemple de la flore - Seseli libanotis - observée à Furfooz ©Adélaïde Bernard",

		furfoozMystery: "The mysteries of Furfooz",
		archaeology: "Archaeology",
		doYouKnow: "Good to know",
		anecdote: "Anecdote",
		edouardDupont: "The Dupont anecdote",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paleontologist",
		palaeontologistDesc:
			"A person who studies the appearance, evolution and extinction of animal species over a very long period, dating back to the very first traces of life on Earth, around 3.5 billion years ago.",
		archaeologist: "Archaeologist",
		archaeologistDesc:
			"A person who studies past human civilisations based on the material traces left behind (buildings, works of art, coins, tombs, manuscripts, everyday objects, etc.)",
		geologist: "Geologist",
		geologistDesc:
			"A person who studies the composition and structure of soils and rocks in the Earth's crust to help explain the evolution of our planet.",
		anthropologist: "Anthropologist",
		anthropologistDesc:
			"A person who studies humans, their behaviour and their evolution. On archaeological sites they analyse human remains to determine their conditions of life and death. They also study burials (types of tomb, adornments, offerings, furniture) to be able to reconstruct any funeral ceremonies carried out by the relatives of the deceased.",
		sediment: "Sediment",
		sedimentDesc:
			"deposit that forms from the weathering or disintegration of ancient rocks.",
		experimentalArchaeology: "Experimental archaeology",
		experimentalArchaeologyDesc:
			"archaeological discipline involving the restoration, through experimentation, of buildings or objects using the construction and manufacturing techniques of the period.",
		necropolis: "Necropolis",
		necropolisDesc:
			"equivalent of a cemetery in Antiquity, revealed by archaeology. Graves may be located in underground excavations or excavated from the ground.",
		hypocaust: "Hypocaust",
		hypocaustDesc:
			"an underground room containing a system for heating baths, thermal rooms or even living areas.",
		darwinism: "Darwinism",
		darwinismDesc:
			"set of biological theories by Darwin and his disciples according to which natural selection is the essential factor in the transformation of living species.",
		prospecting: "Prospecting (archaeology)",
		prospectingDesc:
			"Prospecting consists of observing and studying an archaeological site without excavation. It can be done on foot (by picking up objects scattered over the surface), from an airplane (to see anomalies appear in relief) or even with radar devices that give an indication of potential buried buildings.",
		ossuary: "Ossuary",
		ossuaryDesc:
			"A pile of bones. It can also be a building where human bones are piled up.",

		palaeolithic: "Upper Paleolithic",
		palaeolithicDesc:
			'Third and final phase of the Paleolithic, this period is between -40,000 and -10,000 years. It is marked by the diversification of tools and the appearance of art. This is a period of major transition in the evolution of humans since after the disappearance of the Neanderthals around 30,000 BC, Homo sapiens became the only species representing the genus Homo. Its Greek etymology means "the age of the old stone", as opposed to "the age of the new stone", which designates the Neolithic which succeeded it.',
		magdalenian: "Magdalenian",
		magdalenianDesc:
			"Also called by Édouard Dupont “the age of the reindeer”, the Magdalenian is the most recent culture of the Upper Paleolithic. It developed in our regions between 15,000 and 10,000 years before our time, at the end of the last ice age. Here we see the rise of art and bone work.",
		neolithic: "Neolithic",
		neolithicDesc:
			"A period marking the end of prehistory, between 6,500 and 2,100 years before our time, when humans became sedentary and developed agriculture.",
		antiquity: "Antiquity",
		antiquityDesc:
			"The period from the invention of writing to the early Middle Ages, that is, the period between 3500 BC and 476 AD. (date of the fall of the Western Roman Empire)",
		lateRoman: "Late Empire",
		lateRomanDesc:
			"Period of the Roman Empire which extends from the end of the 3rd century to the fall of the Western Empire in 476. This period illustrates the transition between Antiquity and the Middle Ages.",
		highMiddleAges: "Early Middle Ages",
		highMiddleAgesDesc:
			"Period extending from the end of Antiquity to the end of the Carolingian period, or the end of the 10th century.",
		classicalMiddleAges: "High Middle Ages",
		classicalMiddleAgesDesc:
			"also called the Central Middle Ages, this period between the 11th and 13th centuries AD is characterised by a fragmentation of authority, the organisation of the feudal system and the development of castle architecture.",
	},
	{
		id: "de",
		flag: `url(${flagDe})`,
		lang: "Nederlands",
		/* Splashscreen */
		mainTitle: "Furfooz's Odyssey",
		mainTitleLine1: "Furfooz's",
		mainTitleLine2: "Odyssey",
		mainSubtitle: "In de voetsporen van de mens",
		start: "Start",
		/* Menu */
		timeline: "Frieze",
		map: "Kaart",
		glossary: "Lexicon",
		/* Map */
		repereMap: "Oriënteer je op de kaart",
		enjoyExp:
			"Om het meeste uit de Furfooz ervaring te halen, raden we je aan om je locatie met anderen te delen",
		letsgo: "We gaan!",
		notNow: "Ik ben niet ter plaatse",
		ok: "Ok",
		dotInactiv1: "Dit punt is nog niet actief!",
		dotInactiv2: "Eerst moet je de vorige punten vrijspelen.",
		restartExp: "De ervaring herhalen?",
		/* Credits */
		creditsTitle: "Furfooz's Odyssey",
		immersiveGame:
			"Een meeslepend spel om de schatten van Furfooz te ontdekken in de voetsporen van Edmond, de beroemde Belgische archeoloog.",
		manyMarquage:
			"Ga naar de verschillende markers langs de route en vind de geheime code die exclusieve content en veel verrassingen zal onthullen!",
		playingInPlace: 'Afspelen in de modus “afstandsbediening”',
		cutGeoloc:
			"Schakel geolocatie uit en krijg toegang tot inhoud zonder raadsels te beantwoorden",
		resetExp: "Het experiment resetten",
		credits: "Credits",
		timescopeProject: "Een Timescope-project",
		creditsContent: "%bInhoud%b Musée de l'homme de Bruxelles",
		creditsPhotos: "%bFotografie%b Fotograaf",
		creditsTexts: "%bTeksten %bOona Farell",
		creditsDesign: "%bOntwerp van de mobiele applicatie%b Felix Marquette",
		/* SecretCode */
		secretCode: "Geheime code",
		validate: "Bevestig",
		validate2: "Probeer het opnieuw",
		badanswer: "Het is niet dat...",
		bravo: "Goed gedaan!",
		continue: "Ga verder",
		slideToDiscover: "Klik om verder te gaan",
		/* Poi */
		test: "Lorem ipsum...",
		legend: "Afbeeldingsonderschrift",
		backMap: "Terug naar kaart",
		knowMoreWith: "Lees meer",
		backTime: "Terug in de tijd",
		nowTime: "Terug naar het heden",
		temporalFilter: "Tijdfilter",

		t300ad: "300 N.CHR.",
		t1200ad: "1200 N.CHR.",
		t10000bc: "10 000 V.CHR.",

		/* Timeline */
		timelineTitle: "Tijdlijn",
		scrollArrowsExpl:
			"Pssst... Als je moe wordt van het scrollen, gebruik dan de pijltjes om sneller van de ene periode naar de volgende te gaan!",
		explanationTimeline:
			"Het moderne tijdperk vertegenwoordigt slechts een klein deel van de menselijke geschiedenis. Kun jij de stappen van de eerste mensen in Furfooz terugvinden in dit fries waarin elke pixel overeenkomt met 1 jaar?",
		timelineBottom: "Je hebt het einde bereikt! Goed gedaan :)",
		timelineBackFutur: "Terug naar de toekomst",
		/* Glossary */
		explanationGlossaryLine1:
			"Hier zijn enkele van de technische termen die je tot nu toe misschien bent tegengekomen.",
		explanationGlossaryLine2:
			"Ontdek andere interessante punten om je lexicon aan te vullen!",
		unlockTo: "Ontgrendeld op ",
		glossaryUnlock: "Nieuwe term toegevoegd aan het lexicon!",
		goGlossary: "Zie de woordenlijst",
		/* Cookies */
		cookiesTitle: "Deze website gebruikt cookies",
		cookiesContent:
			"We gebruiken cookies om je voortgang in het spel op te slaan. Door verder te gaan, accepteer je ons gebruik van cookies.",
		cookiesAccept: "Accepteer",
		cookiesDenied: "Afval",
		cookiesReadPrivacyPolicy: "Lees ons privacybeleid.",
		/* PrivatePolicy */
		privatePolicyTitle: "Privacybeleid",
		/* EndExp */
		welldone: "Goed gedaan!",
		allfound: "Je hebt alle schatten van Furfooz gevonden",
		allfound2: "Edouard Dupont zou trots op je zijn.",
		shareExp: "Deel uw ervaring",
		shareExpWithFriends: "Deel je ervaring met je vrienden!",
		link: "Link",
		x: "X",
		whatsapp: "Whatsapp",

		/* ********** */
		/* Quiz Datas */
		/* ********** */

		/* thermalBaths */
		thermalBathsRiddleDisc: "Je hebt het eerste raadsel opgelost!",
		thermalBathsRiddleTitle: "Kalksteen",
		thermalBathsRiddleDesc:
			"Fantastisch ! In feite zijn de thermale baden gebouwd uit kalksteen. Om het materiaal te binden gebruikten de Romeinen compacte grijsachtige mortel en fijn riviergrind.",

		/* Hauterecenne */
		hauterecenneRiddleDisc: "Gefeliciteerd met je tweede puzzel!",
		hauterecenneRiddleTitle: "Munt geslagen op naam van Constantijn III",
		hauterecenneRiddleDesc:
			"Goed gedaan! Op het plateau van Hauterecenne hebben archeologen munten ontdekt... die vele eeuwen bestrijken!",

		/* Nutons */
		nutonsRiddleDisc: "Weer een geslaagde puzzel!",
		nutonsRiddleTitle: "Rendiergewei waarop een bizonkop of bij het omdraaien een mammoetkop werd gegraveerd (zie hier)",
		nutonsRiddleDesc:
			"Bravo! Bij Trou des Nutons ontdekte men een rendiergewei waarin de kop van een bizon werd gegraveerd. Als je hem omdraait, kun je een mammoet zien!",

		/* Frontal */
		frontalRiddleDisc: "Je hebt het vierde raadsel opgelost!",
		frontalRiddleTitle: "Voorhoofdsbeen van een adolescent",
		frontalRiddleDesc:
			"Korte uitleg over het object zodra de codenaam wordt ingegeven: Gefeliciteerd! In 1864 ontdekte Édouard Dupont een voorhoofdsbeen van een adolescent, evenals twee intacte menselijke schedels bewaard in een knekelhuis.",

		/* GoldenPaddle */
		goldenPaddleRiddleDisc: "Nog een raadsel, ga zo door!",
		goldenPaddleRiddleTitle: "Een rotspunt waarin men de sedimenten en geologische lagen kan zien",
		goldenPaddleRiddleDesc:
			"Kort uitleg over het object zodra de codenaam wordt ingevoerd: Gefeliciteerd! Dit stuk rots is een waardevolle informatiebron voor paleontologen. Binnenin ziet men sedimenten, afzetting die ontstaat door het neerslaan van materialen die in een vloeistof zijn gesuspendeerd of opgelost. Hierdoor kan men de aanwezigheid van de mens in de grot dateren.",

		/* Reuviau */
		reuviauRiddleDisc: "Dat was het laatste raadsel!",
		reuviauRiddleTitle: "Magdalénien-geklopte vuursteen",
		reuviauRiddleDesc:
			"Korte uitleg over het object zodra de codenaam wordt ingevoerd: Gefeliciteerd! U heeft het einde van uw reis bereikt. Geslepen vuurstenen zijn de typische werktuigen van de prehistorie. De Furfooz-holten onthulden hiervan talrijke soorten uit het tijdperk magdalénien en het midden-neolithicum.",

		/* ************** */
		/* Timeline Datas */
		/* ************** */

		blank: "",

		/* Epoque contemporaine */
		epoqueContemporaine: "Époque contemporaine",
		epoqueContemporaineFull: "Époque contemporaine | 1789 à nos jours",
		y2024: "Le site de Furfooz est à nouveau fouillé ! Cette campagne se concentre sur le plateau de Hauterecenne.",
		y1985y1986: "Campagne de fouilles archéologiques dans plusieurs cavernes préhistoriques, sous la direction du Professeur M. Otte.",
		y1974y1976: "Campagne de fouilles du site romain et médiéval sous la direction de M.R.Brulet, restauration des murs de fortifications et dégagement de la base du donjon.",
		y1962: "Découverte à Furfooz du trajet de la Lesse souterraine.",
		y1956: "Fin des travaux de reconstruction des thermes romains à Furfooz, sous la direction de M.J. Breuer",
		y1948: "Furfooz est classée réserve naturelle.",
		y1900y1902: "De nouvelles fouilles sont entreprises à Furfooz par Rahir. Il met au jour l’Abri de l’Ossuaire, le Trou du Crâne ou encore le Trou de la Mâchoire.",
		y1872: "À la suite de ses recherches commencées dans les grottes mosanes en 1864, Edouard Dupont publie Les temps préhistoriques en Belgique : l'Homme pendant les âges de la pierre dans les environs de Dinant-sur-Meuse. L'ouvrage reprend ses études des trous, condense tout son parcours et livre ses conclusions au sujet de la Préhistoire en Belgique.",
		y1868: "Edouard Dupont prend la direction du Musée royal d’Histoire naturelle de Belgique, haute fonction qu’il assurera de manière éclairée et autoritaire durant 41 années en agrandissant les espaces et les collections.",
		y1865: "Edouard Dupont fouille le Trou Reuviau et le Trou Rosette.",
		y1864: "Edouard Dupont est le premier archéologue mandaté par le Gouvernement belge pour effectuer des fouilles préhistoriques dans les grottes du bassin mosan. Dupont commence dans les grottes de la vallée de la Lesse, en particulier par le Trou des Nutons à Furfooz. Ces travaux sont considérés comme les premières fouilles de grottes officielles au monde.",
		y1841: "Naissance d'Édouard Dupont.",
		y1829y1833: "Le docteur Ph.C.Schmerling fouille pour la première fois les grottes préhistoriques en Wallonie. C’est l’un des premiers a apporté la preuve de la contemporanéité de l’homme avec des espèces disparues comme le mammouth ou le rhinocéros laineux.",
		y1830Title: "Indépendance de la Belgique",
		y1830: "L'occupation de Bruxelles par l'armée hollandaise, entre le 23 et le 27 septembre 1830, provoque la création d'un comité révolutionnaire. Ce comité se mue en gouvernement provisoire, après le repli des Hollandais. Le 4 octobre 1830, le gouvernement provisoire proclame l'indépendance des neuf provinces belges. Il charge une commission d'élaborer une nouvelle constitution.",
		y1815Title: "Promulgation de la constitution des Pays-Bas unis",
		y1815: "Afin d’intégrer parfaitement les provinces « belges » aux provinces « hollandaises », le roi Guillaume d’Orange fait revoir la Grondwet de mars 1814 et ses 146 articles par une commission hollando-belge. Guillaume Ier promulgue le texte de la nouvelle Constitution, le 24 août 1815.",
		y1793march26Title: "Seconde restauration autrichienne",
		y1793march26: "Alors que les Français se replient dans le nord après une série de victoires de l’armée autrichienne, les Autrichiens rétablissent le prince-évêque sur son siège liégeois et entrent à Bruxelles le 26 mars 1793. Accueillis favorablement par la population, ils rétablissent les Pays-Bas autrichiens dans leur statut antérieur et l’empereur désigne un nouveau gouverneur en la personne de l’archiduc Charles.",
		y1793march01Title: "Annexion à la France du pays wallon",
		y1793march01: "La Convention nationale décide, depuis Paris, l’annexion à la France de l’ensemble des anciens territoires de la principauté de Liège, de la principauté abbatiale de Stavelot-Malmedy et des provinces des Pays-Bas autrichiens. Commissaire de la république désigné à Liège et à Namur, Danton demande cette annexion le 31 janvier lors de son fameux discours sur la théorie des frontières naturelles.",
		y1792y1793: "Le Trou des Nutons à Furfooz sert aux Français de fabrique de salpêtre, une substance servant à la fabrication de la poudre à canon.",
		y1789y1791Title: "Révolution liégeoise",
		y1789y1791: "Ce mouvement révolutionnaire d'inspiration démocratique fait écho, dans la principauté de Liège, au début de la Révolution française. Quand les français prennent la Bastille le 14 juillet, les insurgés prennent l'hôtel de ville le 18 août 1789. Cela provoque l'exil du prince-évêque le 26 août. Celui-ci, momentanément rétabli par l'Autriche en février 1791, procéda à une sévère répression.",

		
		/* Période Moderne */
		periodeModerne: "Période moderne",
		periodeModerneFull: "Période moderne | 1492 à 1789",
		periodeModerneDesc: "Traditionnellement, les historiens font débuter la période Moderne à la découverte des Amériques et font coïncider sa fin avec celle de la Révolution française. Après les différentes révolutions, on appelle également cette période l’Ancien Régime.",
		y1789y1799Title: "Révolution française",
		y1789y1799: "Cette période de bouleversements politiques et sociaux en France et dans ses colonies va inspirer l’ensemble de l’Europe, y compris la Belgique. Traditionnellement, on la fait commencer à l'ouverture des États généraux le 5 mai 1789 et finir au coup d'État de Napoléon Bonaparte le 9 novembre 1799 (18 brumaire de l'an VIII). La Révolution met fin à l'Ancien Régime, notamment à la monarchie absolue remplacée par la monarchie constitutionnelle (1789-1792), puis par la Première République.",
		y1776: "Déclaration d'indépendance des Etats-Unis d'Amérique (13 colonies).",
		y1748Title: "Traité d’Aix-la-Chapelle",
		y1748: "Restitution du pays wallon (Tournai, Mons, Charleroi, Namur) par Louis XV aux Pays-Bas de l'impératrice Marie-Thérèse. Entamée en 1740, la Guerre de Succession d’Autriche touche le pays wallon lorsque les troupes françaises s’emparent tour à tour de Tournai, Mons, Charleroi et Namur. Parti à la conquête des Provinces-Unies, Louis XV finit par accepter un traité de Paix, en 1748, signé à Aix-la-Chapelle. Le pays wallon est restitué à l’impératrice Marie-Thérèse, affaiblie par rapport à la Prusse : les Pays-Bas autrichiens sont reconstitués pour quelques années.",
		y1568y1648Title: "Guerre de Quatre-Vingt-Ans ou révolte des Pays-Bas.",
		y1568y1648: "Soulèvement armé mené contre la monarchie espagnole par une partie des Dix-Sept Provinces des Pays-Bas espagnols. Ayant pris forme autour de la révolte des Gueux, revendiquant la liberté religieuse pour le protestantisme, la révolte prend aussi la forme d'une guerre civile entre les sept provinces du Nord (actuels Pays-Bas), où la Réforme est bien ancrée, et les Pays-Bas méridionaux (actuels Belgique, Luxembourg et Nord-Pas-de-Calais), catholiques et loyalistes.",
		y1566: "Deux cents usines fonctionnent en territoire wallon, la France entière n’en compte que le double. Le pays wallon s’impose comme l’une des régions sidérurgiques les plus denses d’Europe occidentale.",
		sXVIsXVIII: "les provinces wallonnes relèvent des Pays-Bas dirigés par les Habsbourg, d'Espagne, d’abord, jusqu'en 1714, puis d'Autriche, jusqu'en 1794.",
		y1566: "C’est la plus ancienne mention du mot « wallon » retrouvée par Albert Henry dans les Mémoires du chroniqueur Jean de Haynin. le terme wallon devient alors d’usage général dans un ensemble qui met en présence un bloc de langue romane et un bloc de langue thioise dans un même cadre politique.",
		y1517: "Naissance du protestantisme et de la Réforme luthérienne.",

		/* Moyen Âge */
		moyenAge: "Moyen Âge",
		moyenAgeFull: "Moyen Âge | 476 au XVe siècle",
		moyenAgeDesc: "Période commençant au Ve siècle après J.-C. avec l'effondrement et le morcellement politique et culturel de l'Empire romain et s'achevant vers la fin du XVe siècle. avec l'essor de la Renaissance italienne, la naissance des nations modernes et la découverte des nouveaux continents.",
		y1492:"Découverte de l’Amérique. Début de la période moderne.",
		y1454:"Invention de l’imprimerie.",
		sXIII:"Le site de Hauterecenne perd de son importance stratégique et est progressivement abandonné par l’Homme.",
		y1000y1100: "Développent des châteaux forts en pierre à vocation défensive.",
		sXIsXIIITitle: "Moyen Âge classique",
		sXIsXIII: "Aussi appelée Moyen Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		y476sXTitle: "Haut Moyen Âge",
		y476sX: "Période qui s’étend de la fin de l’Antiquité à la fin de la période carolingienne, soit à la fin du Xe siècle. C’est au début de cette période que la forteresse de Hauerecenne est occupée de façon permanente.",
		sV: "Diffusion du christiannisme en Wallonie.",
		sVsVIIITitle: "Période Mérovingienne",
		sVsVIII: "Les Mérovingiens forment une dynastie qui régna du Vᵉ jusqu'au milieu du VIIIᵉ siècle sur une très grande partie de la France et de la Belgique actuelles, ainsi que sur des parties de l'Allemagne, des Pays-Bas et de la Suisse.",

		/* Antiquité */
		antiquite: "Antiquité",
		antiquiteFull: "Antiquité | -3500 à +476",
		antiquiteDesc: "Période qui s’étend de l’invention de l’écriture au début du Moyen Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		y303Title: "Réorganisation administrative de l’empire par Dioclétien",
		y303: "Quand l’empereur Dioclétien (284-305) procède à la réorganisation générale de l’Empire, il distingue désormais, dans la province Belgica, la Belgique supérieure ou première (cap. Trèves), la Belgique inférieure ou seconde (cap. Reims). Quant à la Germanie inférieure, elle est désormais rebaptisée Germania Secunda : c’est là que se situe la civitas Tungrorum traversée en son milieu par la Meuse, entre Dinant et son embouchure. La réforme de Dioclétien semble effective dès 303.",
		sIIIsIV: "Occupation continue de la forteresse de Hauterecenne.",
		sIIIy476Title: "Bas-Empire",
		sIIIy476: "Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen Âge.",
		ya52Title: "César conquiert la Gaule",
		ya52: "C’est le début de la romanisation. Ainsi, les habitants de Wallonie deviennent gallo-romains, abandonnent leurs langues celtes au profit du latin, intègrent la culture et, progressivement, acquièrent la citoyenneté romaine.",
		ya450Title: "Les Celtes s’implantent en Wallonie",
		ya450: "Ils seront à la base d’une riche civilisation, notamment en matière d’industrie (métallurgie) et d’artisanat de qualité. Dès ce moment, les Germains utilisent le terme Walha pour désigner ces Celtes habitant aux frontières sud et ouest de leurs régions. Les Celtes s’illustrent par leur métallurgie (bas-fourneaux), pratiquant la forge et un artisanat de qualité inscrit dans de véritables circuits commerciaux.",
		ya750ya27Title: "Âge du Fer",
		ya750ya27: "Période où les sociétés se structurent considérablement. La sidérurgie se développe dans nos régions. L’époque de Hallstatt (ou Premier Âge du Fer) s'étend de 750 à 480 av. JC et se caractérise par un renforcement du pouvoir. Parallèlement, les richesses sont concentrées entre les mains de quelques individus et la société est par conséquent de plus en plus hiérarchisée, de nombreuses fortifications sont érigées. La période de La Tène (ou Second Âge du Fer), située entre 480 av. J-C et le règne d’Auguste, est quant à elle marquée par un morcellement du pouvoir, des tribus tissant alors entre elles un réseau d’alliances et de clientèle.",
		ya2300ya750Title: "Âge du Bronze",
		ya2300ya750: "Sédentarisées depuis le Néolithique, les hommes et les femmes de l’âge du Bronze perfectionnent leurs techniques agricoles et deviennent des virtuoses de la métallurgie du bronze. C’est aussi le moment de la naissance de l’écriture en Mésopotamie.",

		/* Neolithique */
		neolithique: "Néolithique",
		neolithiqueFull: "Néolithique | -6500 à -2100",
		neolithiqueDesc: "Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		ya4000Title: "Néolithique moyen",
		ya4000: "Développement du procédé d’inhumation, c’est-à-dire d’enterrer ses morts, dans toute la Belgique. Aussi, la Wallonie connaît son âge d’or préhistorique, marqué notamment par l’érection de fortifications et une exploitation « industrielle » du silex.",
		ya6000Title: "Néolithique supérieur et les premières traces néolithiques en Wallonie",
		ya6000: "Le courant de néolithisation de l’Europe du Nord-Ouest né il y a environ 10 000 ans au Proche-Orient. Il se propage vers l’ouest jusque dans les Balkans avant de se scinder en deux : une partie s’est développée dans le Sud de la France et en Espagne et l’autre dans les Balkans et le bassin du Danube moyen, où s’est constituée la civilisation dite rubanée, en référence aux décors en ruban des poteries. Le Danubien a ensuite gagné le nord de l’Europe jusqu’à nos régions. Les premiers agriculteurs établis « en pays wallon » proviennent donc de ces plaines danubiennes. Cette civilisation des premiers hommes néolithiques, des fermiers semi-sédentaires habitant de grandes maisons de bois, a gagné toute la Hesbaye liégeoise et hennuyère et s’est mêlée aux populations mésolithiques restées prédatrices dans le Condroz et en Campine.",

		/* Mésolithique */
		mesolithique: "Mésolithique",
		mesolithiqueFull: "Mésolithique | -9600 à -6000",
		mesolithiqueDesc: "Aussi appelée Epipaléolithique, cette période intermédiaire est marquée par ce brusque réchauffement climatique du début de notre actuelle période interglaciaire. Les hommes du Paléolithique s’y adaptent et adoptent un mode de vie semi-nomade. Les déplacements saisonniers des chasseurs-cueilleurs deviennent moins fréquents. Ils conservent néanmoins un mode de vie basé sur la chasse, la pêche et la cueillette, qui s’achève avec l’arrivée des agriculteurs-éleveurs du Néolithique, autour de - 6000 en France.",
		ya5500ya2000Title: "Transgression marine dunkerquienne",
		ya5500ya2000: "Transgression marine de la mer du Nord, c’est-à-dire l’avancée de trait de côte sur à la montée du niveau de la mer, qui envahit progressivement la plaine côtière notamment flamande. Les eaux occupent alors la plaine maritime flamande en y déposant des sables sur 25 à 30 mètres d'épaisseur. Pendant les périodes de repli, les rivières descendant des collines ont apporté des vases argileuses sur lesquelles la végétation s'est installée.",

		/* Paléolithique supérieur */
		paleolitiqueSup: "Paléolithique supérieur",
		paleolitiqueSupFull: "Paléolithique supérieur | -40 000 à -10 000",
		paleolitiqueSupDesc: "Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		ya10000ya9600: "Un réchauffement climatique marque le début de l'Holocène, l'ère géologique actuelle. Cela sonne aussi la fin de la longue période appelée Paléolithique.",
		ya15000ya10000Title: "Le Magdalénien",
		ya15000ya10000: "Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		ya21000: "Phase la plus froide de la dernière glaciation.",
		ya30000: "Extinction des Néandertaliens en Europe.",
		ya34000ya32000: "L’Homo Sapiens, l’homme moderne, commence sa progression à partir de l’Est et atteint nos régions entre 34.000 et 32.000 av. J-C. Homo Sapiens et Homo Neandertalensis occupent des sites identiques.",
		ya40000Title: "L’homme de Spy",
		ya40000: "Dans une grotte située à Spy, des ossements découverts en 1886 permettent aux chercheurs d’identifier les parties d’un squelette qui  se rattache à la famille des Néandertaliens. Pour la première fois, les éléments rassemblés permettent de confirmer l’existence et l’ancienneté d’un type humain de morphologie différente de celle de l’homme actuel. Cette contribution majeure à la meilleure connaissance de l’histoire de notre espèce donne toute son importance à l’Homme de Spy qui aurait vécu, à Spy, il y a 40.000 ans, environ.",

		/* Modern */
		timelineModern: "Epoque moderne",
		today: "Aujourd'hui",
		littleSticksOnSide:
			"Vous voyez ces petits traits sur le coté gauche de l'écran ? Chaque trait marque 10 ans sur cette frise chronologique qui remonte jusqu'aux toutes premières traces de l'homme.",
		/* Classical Middle Ages */
		timelineClassicalMiddleAges: "Moyen Âge Classique",
		americaDiscover: "Découverte de l'amérique",
		romanFall: "Chute de l'empire romain",
		classicalMiddleAgesExpl:
			"Aussi appelée Moyen Âge centrale, cette période comprise entre les XIe et le XIIIe siècles de notre ère se caractérise par un morcellement de l’autorité, l’organisation du système féodal et le développement d’une architecture castrale.",
		classicalMiddleAgesStart: "Début du moyen age",
		classicalMiddleAgesEnd: "Fin du moyen age",
		/* Antiquity */
		timelineAntiquity: "Antiquité",
		antiquityDuration:
			"Période qui s’étend de l’invention de l’écriture au début du Moyen Âge, c’est-à-dire la période entre 3500 av. J.-C. et 476 ap. J.-C. (date de la dislocation de l’Empire Romain d’Occident).",
		basEmpireExpl:
			"Période de l’Empire romain qui s’étend de la fin du IIIe siècle à la chute de l'Empire d'Occident en 476. Cette période illustre la transition entre l’Antiquité et le Moyen Âge.",
		timelineNeolithic: "Néolithique",
		neolithicDuration:
			"Période correspondant à la fin de la préhistoire, entre 6500 et 2100 avant notre ère, lorsque les Hommes deviennent sédentaires et développent l'agriculture.",
		/* Paleolithic */
		timelinePaleolithic: "Paléolithique",
		paleolithicDuration:
			"Troisième et dernière phase du paléolithique, cette période est comprise entre -40 000 et -10 000 ans. Elle est marquée par la diversification des outils et l’apparition de l’art. C’est une période de transition majeure dans l’évolution de l’Homme puisqu’après la disparition des Néandertaliens vers - 30 000, les Homo sapiens deviennent la seule espèce représentante du genre Homo. Son étymologie du grec ancien signifie « l’âge de la pierre ancienne », par opposition à « l’âge de la pierre nouvelle », qui désigne le Néolithique qui lui succédera.",
		timelineMagdalenian: "Magdalénien",
		magdalenianDuration:
			"Aussi appelée par Edouard Dupont l’âge du renne, le magdalénien est la culture la plus récente du Paléolithique supérieur. Se développant dans nos régions entre 15 000 et 10 000 ans avant notre ère, à la fin de la dernière période glaciaire, on y voit l’essor de l’art et du travail de l’os.",
		startPeriod: "start",
		endPeriod: "end",

		/* ********** */
		/* Pois Datas */
		/* ********** */

		/* parking */
		parking: "Parkeerplaats",

		/* accueil */
		accueil: "Home",

		/* thermalBaths */
		thermalBaths: "Gallo-Romeinse thermale baden",
		thermalBathsTitle: "Gallo-Romeinse thermale baden",
		thermalBathsTitleDeter: "de Gallo-Romeinse thermale baden",
		thermalBathsSubtitle:
			"De thermen, een onmisbare plek in het dagelijks leven van de Romeinse tijd",
		thermalBathsSec1Title: "Het Romeinse Furfooz",
		thermalBathsSec1Content:
			"Waar de Romeinen zich ook vestigen, één iets mag er niet ontbreken: de thermale baden. Furfooz is geen uitzondering op deze regel! In de late oudheid wordt het plateau van Hauterecenne een belangrijke vestiging, bezet door een militie in dienst van Rome. En daarbij moet ook de noodzakelijke infrastructuur aangelegd worden.",
		thermalBathsSec2Title: "Hoe werken thermale baden?",
		thermalBathsSec2Content:
			"Met een bescheiden oppervlakte van 75 m2 omvat het gebouw alle traditionele elementen van dit soort Romeinse etablissementen, zoals daar zijn een verwarmde kamer met een warm bad (caldarium) en een lauw bad (tepidarium), evenals een kamer met een koud bad (frigidarium). De hoofdruimte wordt verwarmd door het hypocaustum, terwijl de koude vleugel aan de buitenkant ligt in een kleine vierhoekige kamer die uitsteekt en die tevens dienst doet als kleedkamer.",
		thermalBathsLegend1:
			"Plattegrond en doorsnede van de Romeinse baden volgens J Breuer (1957)",
		thermalBathsEncart1Title: "Waarom precies hier een thermisch etablissement?",
		thermalBathsEncart1Content:
			"Er bestaan veel vragen over de thermale baden van Furfooz: uit welke periode dateren ze? Hoe slaagden de Romeinen erin om water tot aan het gebouw te krijgen? De thermale baden zijn niet met een fort verbonden en er werd ook geen Romeinse villa ontdekt, dus hoe valt hun aanwezigheid ver van elke leefgebied dan te verklaren? De meest waarschijnlijke aanname is dat deze baden werden gebruikt door de bewoners van het plateau, zoals op veel vergelijkbare militaire locaties, daterend van het begin van de late oudheid. Wat de toevoer van water betreft bestaan  er verschillende hypothesen. Bij gebrek aan bronnen op het plateau denkt geoloog Van de Poel aan de aanwezigheid ​​van een waterleiding die de thermale baden zou hebben gevoed vanuit een bron zo'n 600 meter verderop, in Fond-des-Vaux. Archeologen hebben nog steeds veel vragen te beantwoorden!",
		thermalBathsSec3Title: "Niet enkel een bad maar ook een sociale en maatschappelijke plek!",
		thermalBathsSec3Content:
			"Zorgen voor de hygiëne van de stad, was de belangrijkste functie van de thermale baden maar daarnaast fungeren deze openbare baden ook als ideale ontmoetingsplek. In de Romeinse samenleving gaan mensen er elke dag heen om vrienden te ontmoeten of om er nog wat te sporten, ongeacht hun sociale status! Iedereen is er welkom, zonder onderscheid, zowel mannen als vrouwen (in aparte ruimtes en op andere tijdstippen).",
		thermalBathsEncart2Title:
			"De experimentele archeologie, een wetenschappelijk avontuur!",
		thermalBathsEncart2Content:
			"Deze archeologen gaan ervoor! De thermen van Furfooz werden voor het eerst onderzocht in 1876-1877 door Alfred Bequet van de Archeologische Vereniging van Namen. Daarna was het wachten tot de jaren dertig alvorens er door Jacques Breuer nieuwe onderzoeken werden uitgevoerd. Een nieuw avontuur in de experimentele archeologie heeft het van 1956 tot 1958 mogelijk gemaakt deze verdwenen Romeinse baden getrouw en levensgroot te reconstrueren. Het brede publiek kreeg op die manier een toegankelijke weergave van hun herkomst en het gebruik. Een knap staaltje pedagogie.",
		thermalBathsSec4Title:
			"Het begin van de middeleeuwen betekent het einde van de Gallo-Romeinse thermale baden",
		thermalBathsSec4Content:
			"Hoewel de Romeinse baden duidelijk zichtbaar zijn dankzij de zorgvuldige reconstructies, blijkt de geschiedenis van de site veel complexer. In het begin van de middeleeuwen wordt het plateau van Hauterecenne tijdelijk verlaten door de bewoners van het Romeinse fort. Tegen het laatste derde deel van de 4e eeuw zullen de thermale baden ontmanteld worden en komt er een kleine necropolis.",
		thermalBathsLegend2: "Tekening van de graven van de necropolis door A.Bequet",
		thermalBathsEncart3Title:
			"Een necropolis op de ruïnes van de Gallo-Romeinse thermen",
		thermalBathsEncart3Content:
			"Om haar doden te begraven zette de nieuwe gemeenschap, die aan het einde van de 4e eeuw de versterkte site bezette, haar zinnen op het thermale gebied, dat een woestenij was geworden. En er ging niets verloren want voor het bouwen van de muren werd enkel het aanwezige materiaal van op het plateau gebruikt.\n\nDe plaats die nodig is om de doden te kunnen begraven, is gering aangezien men er maar vijfentwintig graven ontdekt. De tombes zijn ingegraven, enkele bevatten een kist. De studie van deze latere necropolis vertelt veel over de bewoners, vooral door de ontdekking van talrijke voorwerpen of grafgiften. Deze stukken suggereren een bevoorrechte status van de overledene en getuigen van het gemilitariseerd karakter van de groep. Ten onrechte werd gedacht dat het om een Germaanse ​​immigranten gemeenschap ging, maar het servies en de prachtige riemen behoorden tot de laat-Romeinse militaire cultuur. In deze tijd van verandering was er in Furfooz waarschijnlijk sprake van een culturele mix!",
		thermalBathsSec5Title:
			"Archeologisch nieuws, de site is opnieuw opgegraven!",
		thermalBathsSec5Content:
			"In 2024 wordt de aftrap gegeven voor nieuwe opgravingen om op die manier meer te leren over onze voorouders, hun levensstijl en hun infrastructuur. Wat zal er ontdekt worden? Het is aan de archeologen om het ons te vertellen!",

		/* Hauterecenne */
		hauterecenne: "Versterkte hoogvlakte van Hauterecenne",
		hauterecenneTitle: "Versterkte hoogvlakte van Hauterecenne",
		hauterecenneTitleDeter: "de Versterkte hoogvlakte van Hauterecenne",
		hauterecenneSubtitle: "Eén fort, vele levens!",
		hauterecenneQuote1:
			"“De mens heeft zich doorheen de geschiedenis op deze plaats [Furfooz] gevestigd naargelang de verschillende omstandigheden van zijn tijd.”",
		hauterecenneQuoteAuthor1:
			"Édouard Dupont, Prehistorie in België: de mens tijdens de steentijd in de omgeving van Dinant-sur-Meuse, 1872.",
		hauterecenneSec1Title:
			"Sporen van nederzettingen onderzocht",
		hauterecenneSec1Content:
			"Het favoriete onderzoeksgebied in Furfooz is de paleontologie. Toch bestaan er nog andere en latere sporen van menselijke aanwezigheid die onze aandacht verdienen! Tijdens het onderzoeken van het plateau van Hauterecenne ontdekten archeologen menselijke activiteit uit het late keizerrijk en de vroege middeleeuwen. \nHet plateau werd in de 19e eeuw met mondjesmaat onderzocht en het was wachten tot de jaren dertig en vervolgens tot 1980 om het plateau te onderwerpen aan diepgaande studies.\nOp weg om al zijn geheimen te ontdekken!",
		hauterecenneSec2Title:
			"Hauterecenne, een strategisch plateau voor het neerstrijken… van een Romeins garnizoen!",
		hauterecenneSec2Content:
			"Door de overblijfselen van de oude wallen te bestuderen, hebben archeologen op de Furfooz-site de aanwezigheid van een Romeins garnizoen uit het late keizerrijk ontdekt. Maar waarom viel de keuze op deze plek? Vanwege de strategische ligging natuurlijk! Het Hauterecenne-plateau is een hoog voorgebergte dat uitkijkt over de rivier en dat profiteert van de natuurlijke bescherming aan deze kant, maar evengoed aan de andere kant, waar de Ry des Vaux een kleine steile vallei heeft gegraven. Doordat het boven op een steile klif ligt, 60 meter boven de Lesse, is het dus heel gemakkelijk om de locatie te versterken en te beschermen tegen elke vijandelijke aanval! Op het plateau bevinden zich nog steeds de goed bewaarde overblijfselen van een aantal oude bouwwerken en versterkingen uit verschillende perioden. De Archeologen vertellen het je graag.",
		hauterecenneLegend1:
			"Illustratie van de constructie van een dammuur door Benoît Clarys",
		hauterecenneEncart1Title:
			"Archeologen bestuderen deze strategische plaatsen",
		hauterecenneEncart1Content:
			"“Sinds het midden van de 19e eeuw zijn er verschillende opgravingswerken uitgevoerd en de grote verdienste van Raymond Brulet is dat hij de studie van de site heeft hervat […]. De site werd dus bewoond vanaf de Romeinse tijd tot de 13e eeuw, met als hoogtepunt het midden van de 3e eeuw en het begin van de 5e eeuw. »  Raymond Brulet, De vestingen van Hauterecenne in Furfooz, (publicatie van de geschiedenis van de kunst en de archeologie van de Katholieke Universiteit Leuven XIII), 1978.",
		hauterecenneSec3Title: "Destijds een klein middeleeuws fort!",
		hauterecenneSec3Content:
			"Raymond Brulet zet ons op pad… Het fort zou dus in de loop van de tijd veranderen? Jawel. Na de val van het Romeinse Rijk en het vertrek van zijn troepen werd de plek af en toe versterkt. We hebben weinig informatie over de bewoners die zich daar vestigden, maar de aanwezigheid van een versterkt huis en de toevoeging van wallen duiden op de aanwezigheid van een kleine plaatselijke heer. Aan het einde van de 13e eeuw verloor de site zijn strategische belang en werd deze geleidelijk door de mens verlaten.",
		hauterecenneEncart2Title: "Weet jij wat een versterkt huis is?",
		hauterecenneEncart2Content:
			"Het versterkte huis is de voorloper van de versterkte kastelen. Bescheiden van formaat, alleen te betreden via de eerste verdieping. Dus als het huis wordt aangevallen, kan de trap worden verwijderd en zijn de bewoners veilig!",
		hauterecenneLegend2:
			"Mur de barrage sur le site du Katzenberg dans le massif de l’Eifel",
		hauterecenneSec4Title:
			"Tijdperken overlappen elkaar. Hoe kunnen we ze onderscheiden?",
		hauterecenneSec4Content:
			"Om de geschiedenis en evolutie van de menselijke bewoning van een plek in kaart te brengen, omringt de goed uitgeruste archeoloog zich met specialisten. In het geval van het versterkte plateau is het noodzakelijk om de perioden te kunnen onderscheiden, ook al overlappen ze elkaar. Hiervoor werkt men met een relatieve tijdlijn en gaat men sites vergelijken. Deze lange weg leidde de archeologen naar een belangrijke ontdekking: de aanwezigheid van twee stuwmuren.",
		hauterecenneSec5Title: "Het geval van de twee stuwmuren",
		hauterecenneSec5Content:
			"Jawel, de muren weerspiegelen de verschillende tijdperken! De eerste dammuur, de buitenste, dateert uit het begin van het laat-Romeinse rijk. Zeer smal en gebouwd volgens dezelfde techniek als de thermale baden, wat erop wijst dat het moet zijn uitgerust met een aarden wal die er tegenaan leunt of met een houten borstwering, verankerd in de muur. Om een ​​onbekende reden werd vervolgens een tweede stuwmuur opgetrokken. Die bevat een grote deur voor karren en zijn breedte is deze keer meer gangbaar. Hij dateert zonder twijfel uit de 5e eeuw. Bij de laatste verdediging van de site, tijdens de hoge middeleeuwen, werd deze muur versterkt en vervolgens verhoogd. Het circulatiesysteem bovenaan de infrastructuur rust op een reeks arcades.",
		hauterecenneSec6Title: "Wat als men deze plek opnieuw zou doorzoeken?",
		hauterecenneSec6Content:
			"In 2024 wordt een nieuwe opgravingscampagne georganiseerd om meer te weten te komen over onze voorouders, hun levensstijl en hun infrastructuur. Wat zal er ontdekt worden? Alleen de archeologen kunnen het ons vertellen!",

		/* Nutons */
		nutons: "Trou des Nutons",
		nutonsTitle: "Trou des Nutons",
		nutonsTitleDeter: "de Trou des Nutons",
		nutonsSubtitle: "Tussen paleontologische ontdekkingen en populaire legende",
		nutonsSec1Title: "De Trou des nutons, eerste onderzochte grot",
		nutonsSec1Content:
			"In 1864 was onze dierbare Édouard Dupont de eerste die begon met het uitgraven van de Trou des Nutons. Hij koos het als vertrekpunt voor zijn verkenningen omdat alle kenmerken wezen op de vestiging van een kamp magdalénien: goed beschut, op 40 meter boven de rivierbedding, dus dichtbij een waterbron… perfect voor deze semi-nomadische bevolking van rendieren- of paardenjagers!",
		nutonsSec2Title: "De studie van de bodem ontmoet de studie van de mens",
		nutonsSec2Content:
			"Maar hoe trekt Edouard Dupont in vredesnaam zoveel conclusies over een bevolking van zo lang geleden? Door het uitvoeren van wat in de archeologie ‘een peiling’ wordt genoemd. In een heel klein deel van de grot verwijdert het team van Dupont opeenvolgende lagen aarde, tot 8 meter diep, om ze vervolgens te bestuderen. Deze lagen zijn opgebouwd uit sedimenten, dat wil zeggen afzettingen die veel zeggen over het ‘wonen’ en de levensstijl van onze voorouders. Het valt niet te ontkennen dat geologie en archeologie nauw met elkaar verbonden zijn.",
		nutonsLegend1: "Lengtedoorsnede van Trou des Nutons, Édouard Dupont",
		nutonsSec3Title: "De eerste grote ontdekkingen",
		nutonsSec3Content:
			"Édouard Dupont houdt het niet bij peilingen alleen! Hij laat stalagmieten en stenen blokken ontploffen. Daaronder doet hij verbazingwekkende ontdekkingen: overblijfselen van een twintigtal diersoorten, vuurstenen messen en pijlen, munten uit de Gallo-Romeinse tijd en diverse latere gebruiksvoorwerpen. Helaas is er niet het minste spoor van menselijke botten, terwijl het er naar uitzag dat de ontdekking van schedels tot de mogelijkheden behoorde.",
		nutonsSec4Title: "De %lpaleontoloog%l luistert naar de populaire legendes",
		nutonsSec4Content:
			"Nog een reden voor Édouard Dupont om in de Trou aan zijn onderzoeken te beginnen, is de legende die eraan verbonden is. Volgens het volksgeloof waren de Nutons dwergen met een oud en ziekelijk uiterlijk, maar actief, zeer ondeugend en handvaardig. Ze leefden verborgen in rotsen, grotten en ruïnes. ‘s Nachts herstelden deze bovennatuurlijke wezens gebruiksvoorwerpen en meubels, lapten vodden en wasten het linnengoed van de dorpelingen, voor een beetje eten en wat lekkers dat ze bij de ingang van hun verblijven achterlieten. In de zomer kwamen ze massaal naar buiten om vrolijke dansjes uit te voeren in de weilanden. Ondeugend maar heel lichtgeraakt namen ze wraak op diegenen die hen voor de gek hielden of hun geheimen probeerden te ontdekken.",
		nutonsEncart1Title: "Een wetenschappelijke verklaring van de legende?",
		nutonsEncart1Content:
			"Édouard Dupont probeert als volgt de kleine gestalte van de bevolking van Furfooz te verklaren: “De bevolking van Grenelle, en vooral die van Furfooz, was klein. Eerstgenoemde had nog een gemiddelde van 1m62, maar laatstgenoemde zakte naar 1m53. Dat is bijna de gemiddelde grootte van de Lappen. Dit kleinere gestalte sloot echter noch de kracht noch de behendigheid uit, eigen aan wilde bevolkingsgroepen. De botten van de ledematen en de romp zijn robuust, de uitsteeksels en indrukken van het oppervlak wijzen op een zeer uitgesproken spierontwikkeling. Afgezien van deze algemene robuustheid, duidelijker dan wat we gewoonlijk tegenkomen, lijken de skeletten van de bevolking van Furfooz en Grenelle sterk op die van de hedendaagse mens.\nÉdouard Dupont, “Over de schedels van Furfooz”, Verslag van het Congres van de Prehistorie, 1872. pp. 251-252).",
		nutonsQuote1:
			"Even mysterieus als representatief, de Trou des Nutons heeft altijd verrassingen in petto! Vervolg uw verkenning van het reservaat en ontdek nog veel meer geheimen.",

		/* Frontal */
		frontal: "Trou du Frontal",
		frontalTitle: "Trou du Frontal",
		frontalTitleDeter: "le Trou du Frontal",
		frontalSubtitle: "Tussen woongebied en begraafplaats",
		frontalSec1Title:
			"De Trou du Frontal heeft zijn naam te danken aan een ontdekking!",
		frontalSec1Subtitle:
			"Hoe denkt u dat het deze naam kreeg? Heel eenvoudig: omdat onze vriend Édouard Dupont, tijdens het uitgraven van de holte in 1864, al bij de eerste slagen van het houweel een voorhoofdsbeen van een jongvolwassene in handen kreeg. Een opzienbarende ontdekking in de onderzoekswereld; er zullen er nog vele volgen...",
		frontalSec2Title:
			"De ontdekking van twee heel verschillende schedels: een raadsel dat moet worden opgelost",
		frontalSec2Subtitle:
			"Op 10 december van hetzelfde jaar ontdekt de paleontoloog, in aanwezigheid van vooraanstaande gasten, twee intacte menselijke schedels, bewaard in een knekelhuis. Dit opent een enorme waaier aan hypothesen over de bezettingsperiode van deze grotten, maar ook over de levensstijl van onze voorouders.",
		frontalLegend1:
			"Foto van de opgravingen van Trou du Frontal in 1864 in. Waalse Karstatlas",
		frontalEncart1Title: "Verslag van de opgravingen door Dupont",
		frontalEncart1Content:
			"“Deze kleine grot is, zowel vanwege zijn geologische informatie als vanwege het licht dat hij heeft geworpen op de oude etnografie, de belangrijkste die tot nu toe in ons land is ontdekt. Mocht de ontdekker zijn onderzoek regelmatig zo succesvol hebben afgerond, dan zou het moeizame werk dat in onze regio werd ondernomen aanzienlijk korter geweest zijn, en vandaag de dag zou er heel weinig meer te onthullen zijn over de inwoners van België tijdens het quartaire tijdperk. [...] We zullen de gegevens verder onderzoeken van de etensresten in de buitenschuilplaats en de stapel menselijke botten met betrekking tot de grafrituelen van het oude volk.\n\nÉdouard Dupont, Prehistorie in België: de mens tijdens de steentijd in de omgeving van Dinant-sur-Meuse, 1872",
		frontalSec3Title:
			"Fouten maken hoort bij onderzoek!",
		frontalSec3Subtitle:
			"Bij het bestuderen van deze botten linkt Dupont ze ten onrechte aan het tijdperk van de rendierjagers. Maar paleontoloog zijn betekent ook fouten maken! Meer diepgaande studies van deze schedels en van alle materialen uit de Trou du Frontal onthullen dat Dupont een vergissing maakte over de sporen van de magdaléniens, de bekende jagers, en die van de volkeren uit het neolithicum.",
		frontalEncart2Title:
			"Wanneer Darwins theorie licht werpt op de paleontologische wereld",
		frontalEncart2Content:
			"De door Dupont ondernomen opgravingen worden beschouwd als het eerste anatomische argument ten gunste van Darwins theorieën, die een paar jaar vóór deze ontdekkingen, in 1859, werden beschreven in zijn werk The Origin of Species. Dankzij deze schedels begrijpt Dupont de evolutie van de menselijke soort in functie van de verblijfperiodes. Want ja, in de Trou du Frontal woonden mensen uit twee tijdperken!",
		frontalSec4Title: "Mensen uit twee tijdperken: leven en dood",
		frontalSec4Subtitle:
			"Bij de Trou du Frontal hebben archeologische opgravingen zowel gesneden vuurstenen blootgelegd die dateren uit de periode magdalénien, als ook talrijke botten van minstens zestien individuen, mannen, vrouwen en kinderen, die zich duidelijk boven de laag van het niveau magdalénien bevinden. Alles wijst erop dat dit de overblijfselen zijn van een begrafenis uit het midden-neolithicum. Bijna 10.000 jaar scheidden deze mensen en toch kozen ze allemaal de Trou du Frontal als toevluchtsoord!",
		frontalLegend2:
			"Voorbeeld van de plannen van een neolithische begrafenis ontdekt in Furfooz, hier le Trou de la Machoire, naar E.Van den Broeck",
		frontalSec5Title: "Leven en sterven in de Trou du Frontal",
		frontalSec5Subtitle:
			"De stapel botten suggereert eerder een begraafplaats dan een leefgebied. De teraardebestelling, dat wil zeggen het begraven van de doden, breidde zich tijdens het Neolithicum, zo'n 7000 jaar geleden, uit over het hele huidige Belgische grondgebied. De Trou du Frontal is daar een perfect voorbeeld van, aangezien deze zelfs is afgesloten door een plaat, zoals je zou doen bij een tombe, wat bevestigt dat het om een ​​secundair graf gaat uit het midden-neolithicum.",
		frontalSec6Title:
			"Van het frontale gat tot de Trou de la Machoire… nog maar 10 meter te gaan!",
		frontalSec6Subtitle:
			"Begrafenisrituelen zijn alomtegenwoordig op de Furfooz-site. Op een steenworp van de Trou du Frontal had de Trou de la Machoire ongetwijfeld dezelfde functie, aangezien daar vijf skeletten in opmerkelijke staat werden ontdekt. En we weten: het bestuderen van de doden betekent het leven begrijpen! \nKom even langs bij La Flobette, de snackbar niet ver van de Trou du Frontal, om ten volle te genieten van de schoonheid van de natuur.",

		/* GoldenPaddle */
		goldenPaddle: "Grotte de la Gatte d'Or",
		goldenPaddleTitle: "Grotte de la Gatte d’Or",
		goldenPaddleTitleDeter: "de grot van la Gatte d’Or",
		goldenPaddleSubtitle:
			"Een schat aan informatie over de geschiedenis van de mensheid!",
		goldenPaddleSec1Title:
			"Waarom in godsnaam een rots bestuderen om meer over de mens te leren?",
		goldenPaddleSec1Subtitle:
			"“Dit soort studies, die hun bevindingen ontlenen aan de geologie, behoren toe aan de exclusieve kunde van de natuurwetenschappen. Bij dit soort onderzoek helpt de geologie de geschiedenis een handje door het verleden van de mensheid herop te bouwen.” Édouard Dupont, Prehistorie in België: De mens tijdens de steentijd in de omgeving van Dinant-sur-Meuse, 1872\n\nDeze woorden van Édouard Dupont weerspiegelen het belang van de studie van gesteenten en sedimentatie bij het begrijpen van de geschiedenis van de mensheid. Wij leggen het je uit.",
		goldenPaddleLegend1:
			"Foto van de Grotte de la Gatte d’or vandaag ©Timescope",
		goldenPaddleSec2Title:
			"Sedimentatie, een goudmijn voor wetenschappers!",
		goldenPaddleSec2Subtitle:
			"Door de veranderingen in de natuurlijke omgeving te ontcijferen, informeert de zogenaamde geomorfoloog ons over de menselijke activiteiten die deze hebben gevormd. Door de sedimenten te bestuderen, bepaalt hij zowel de oorsprong van de afzettingen (alluvium, afvoer, taluds, enz.) als hun ouderdom. Hij bestudeert ook de opeenvolging van afzettingslagen, omdat elke laag overeenkomt met een tijdperk. Ze zijn zichtbaar dankzij veranderingen in de kleur of textuur van de grond (klei, zand, kiezelstenen, enz.). Deze stratigrafische methode maakt het mogelijk om de aard en chronologie van gebeurtenissen te bepalen. Bij de Gatte d'Or zijn de sedimentaire lagen goed zichtbaar en bieden ze informatie over zowel de menselijke aanwezigheid als over de oorsprong van Furfooz.",
		goldenPaddleSec3Title:
			"Opgravingen die het erfgoed beschadigen, ook dat komt voor…",
		goldenPaddleSec3Subtitle:
			"Aan het begin van de 20e eeuw was de Furfooz-site lange tijd onbewoond en diende het als betalende toeristische attractie. Helaas! Na de talrijke opgravingen van Dupont werd er niets voorzien om de binnenkant te beschermen. De toestroom aan toeristen versnelde het verval van de grotten, vooral als gevolg van het oneigenlijk gebruik van kaarsen die voor verlichting werden gebruikt.",
		goldenPaddleEncart1Title: "De legende vertelt...",
		goldenPaddleEncart1Content:
			"De legende van het land vertelt dat er in de grotten van Furfooz een ‘gatte’, een Waalse geit, zou leven en een schat bewaakt... Dit populaire verhaal dateert uit een ver verleden, toen textiel en financiële instanties nog niet bestonden. Mensen maakten kleine zakjes uit pels en verstopten hun geld waar ze maar konden. Zo zorgvuldig zelfs dat ze ze soms vergaten of dat ze het geheim meenamen in hun graf... Deze vergeetkousen maakten later veel mensen gelukkig! Uit deze legende is de beroemde ‘gatte’ geboren, volledig bekleed met goud!",
		goldenPaddleSec4Title:
			"Tegenwoordig is de Gatte d’or een niet te missen natuurplek!",
		goldenPaddleSec4Subtitle:
			"Gelukkig zet de vereniging Ardenne & Gaume zich vandaag in voor het behoud van dit natuurlijk erfgoed. De menselijke aanwezigheid wordt steeds minder zichtbaar en maakt plaats voor een uitzonderlijk natuurlandschap. Het uitzicht is adembenemend! Bovendien is het Furfooz-reservaat een deel van het jaar gesloten om de natuur te laten herstellen. Zij heeft ook vakantie nodig!",

		/* Reuviau */
		reuviau: "Hole Reuviau",
		reuviauTitle: "Hole Reuviau",
		reuviauTitleDeter: "de Hole Reuviau",
		reuviauSubtitle: "Bij de Trou Reuviau gaat men aan tafel!",
		reuviauSec1Title: "Reuviau, een onopvallende holte maar rijk aan kennis",
		reuviauSec1Subtitle:
			"Vergeet aan het einde van uw wandeling in het natuurreservaat Furfooz niet te stoppen bij de Trou de Reuviau!  Bescheiden van omvang, maar niettemin bijzonder interessant voor paleontologen, vooral voor een beter begrip van de eetgewoonten van de magdaléniens.",
		reuviauLegend1: "Foto van Trou Reuviau vandaag ©Timescope",
		reuviauEncart1Title: "Het doorzoeken van Reuviau door Édouard Dupont",
		reuviauEncart1Content:
			"“Een andere kleine grot, de Reuviau-grot, in een ravijn op de weg van de Lesse naar Furfooz, bracht onder dezelfde geologische omstandigheden botten van paarden, rendieren, beren, enzoverder naar voren, door mensenhanden bewerkt.”\n\nÉdouard Dupont, Prehistorie in België: de mens tijdens de steentijd in de omgeving van Dinant-sur-Meuse, 1872",
		reuviauSec2Title: "De maaltijd van de mens in het tijdperk van de rendieren",
		reuviauSec2Subtitle:
			"Édouard Dupont en vele andere paleontologen ontdekten een reeks rendier- en paardenbeenderen. Alles wijst erop dat deze echte delicatessen waren voor de holbewoners... in die mate zelfs dat men deze periode als eerbetoon 'het tijdperk van de rendieren' noemt! Vuurstenen, opgegraven uit het hol, onthullen veel over hoe deze mensen dieren in stukken sneden, zowel voor voedsel als voor kledij.",
		reuviauEncart2Title: "Dupont over het stenen tijdperk",
		reuviauEncart2Content:
			"“De jacht voorzag hen van voedsel en van hun ruwe kledij; uit botten en vooral vuursteen vervaardigden ze gereedschap en wapens, zonder dat ze metaal konden produceren of zelfs maar het bestaan ​​ervan konden vermoeden. Daarom hebben we deze verre en langdurige periode aangeduid als het Stenen Tijdperk.\nEdouard Dupont, Prehistorie in België: de mens tijdens de steentijd in de omgeving van Dinant-sur-Meuse, 1872",
		reuviauSec3Title: "De jacht tijdens de prehistorie",
		reuviauSec3Subtitle:
			"Tijdens het paleolithicum waren rendieren, die in kuddes door de valleien trokken, de favoriete wildvangst van de prehistorische mens. In verschillende grotten van Furfooz zijn talloze sporen van de jacht op deze dieren gevonden. Als aaibare trekdieren was het ongetwijfeld gemakkelijk om massaal op hen te jagen... maar evengoed om het vlees te drogen! Al deze factoren, bestudeerd door paleontologen, leren ons telkens weer wat bij over onze voorouders.",
		reuviauSec4Title: "Het verkennen van de holtes van Furfooz is nog lang niet voorbij!",
		reuviauSec4Subtitle:
			"U bent op het einde van uw ontdekkingstocht gekomen, maar u heeft ongetwijfeld nog maar een klein deel van het natuurreservaat Furfooz gezien! Aarzel niet terug te komen en alle hoeken van deze weelderige fauna en flora, dat al bijna 70 jaar wordt beschermd, verder te verkennen!\nTot binnenkort.",
		reuviauLegend2:
			"Voorbeeld van de flora - Seseli libanotis - waargenomen bij Furfooz ©Adélaïde Bernard",

		furfoozMystery: "De mysteries van Furfooz",
		archaeology: "Archeologiebijlage",
		doYouKnow: "Bijlage",
		anecdote: "Anekdotische",
		edouardDupont: "Dupont-insert",

		/* ************** */
		/* Glossary Datas */
		/* ************** */

		palaeontologist: "Paleontoloog",
		palaeontologistDesc:
			"wetenschapper  die het leven van vroegere geologische periodes bestudeert aan de hand van fossiele overblijfselen, teruggaand naar de eerste sporen van leven op aarde, ongeveer 3,5 miljard jaar geleden.",
		archaeologist: "Archeoloog",
		archaeologistDesc:
			"iemand die menselijke beschavingen uit het verleden bestudeert op basis van overgebleven materiële sporen (gebouwen, kunstwerken, munten, graven, manuscripten, voorwerpen uit het dagelijks leven, enz.).",
		geologist: "Geoloog",
		geologistDesc:
			"wetenschapper die de samenstelling en de structuur van de bodem en het gesteente van de aardkorst bestudeert om de evolutie van onze planeet te verklaren.",
		anthropologist: "Antropoloog",
		anthropologistDesc:
			"iemand die de mens, zijn gedrag en zijn evolutie bestudeert. Op archeologische vindplaatsen analyseert hij skeletten om de levensomstandigheden en  doodsoorzaken van mensen te bepalen. Ook worden de begraafplaatsen beschreven (soort graf, versieringen, offergaven, grafgiften) om het eerbetoon van de nabestaanden aan de overledene te kunnen reconstrueren.",
		sediment: "Sediment",
		sedimentDesc:
			"afzetting die ontstaat door de verandering of het uiteenvallen van oeroude gesteente.",
		experimentalArchaeology: "Experimentele archeologie",
		experimentalArchaeologyDesc:
			"een vakgebied van de archeologie, dat aan de hand van experimenten erin slaagt om gebouwen of objecten volgens historische constructie- en productietechnieken te reconstrueren.",
		necropolis: "Necropolis",
		necropolisDesc:
			"het equivalent van een grote begraafplaats uit de oudheid, ontdekt aan de hand van archeologische opgravingen. De graven kunnen zich bevinden in ondergrondse souterrains of begraven liggen in de grond.",
		hypocaust: "Hypocaustum",
		hypocaustDesc:
			"ondergrondse ruimte met daarin een verwarmingssysteem voor baden, thermale baden of zelfs woonkamers.",
		darwinism: "Darwinisme",
		darwinismDesc:
			"reeks biologische theorieën van Darwin en zijn aanhangers dat uitgaat van natuurlijke selectie als essentiële factor in de transformatie van levende soorten.",
		prospecting: "Prospectie (archeologie)",
		prospectingDesc:
			"het observeren en bestuderen van een archeologische vindplaats zonder in de grond te graven. Dit kan te voet worden gedaan (door voorwerpen op te rapen die verspreid over het oppervlak liggen), vanuit een vliegtuig (om afwijkingen in het reliëf te zien verschijnen) of zelfs met radarapparatuur die een indicatie geeft van mogelijke begraven gebouwen.",
		ossuary: "Ossuarium",
		ossuaryDesc:
			"Een stapel botten. Het kan ook een gebouw zijn waar menselijke botten opgestapeld liggen.",

		palaeolithic: "Het laat-paleolithicum",
		palaeolithicDesc:
			"De derde en laatste fase van de oude steentijd, tussen 40.000 en 10.000 jaar geleden. Deze periode wordt gekenmerkt door de verscheidenheid aan gereedschappen en de opkomst van kunst. Het is een periode van grote verandering in de evolutie van de mens, aangezien de Homo Sapiens na het verdwijnen van de Neanderthalers zo’n 30.000 jaar geleden, de enige representatieve soort van het geslacht Homo werd. Volgens de Griekse etymologie betekent paleolithicum ‘het tijdperk van de oude steen’, in tegenstelling tot ‘het tijdperk van de nieuwe steen’, wat dan weer het daaropvolgende neolithicum aanduidt.",
		magdalenian: "Het magdalénien",
		magdalenianDesc:
			"Ook wel door Édouard Dupont “het tijdperk van de rendieren” genoemd. Het magdalénien is een van de latere culturen van het laat-paleolithicum. Het ontwikkelde zich in onze streken tussen 15.000 en 10.000 jaar voor Christus, aan het einde van de laatste ijstijd. Het wordt onder andere gekenmerkt door de opkomst van kunst en het graveren van beenderen.",
		neolithic: "Het neolithicum",
		neolithicDesc:
			"De periode die overeenkomt met het einde van de prehistorie, tussen 6.500 en 2.100 jaar voor Christus, toen de mens sedentair werd en de landbouw zich ontwikkelde.",
		antiquity: "De oudheid",
		antiquityDesc:
			"De periode die loopt vanaf de uitvinding van het schrift tot het begin van de middeleeuwen, dat wil zeggen de periode tussen 3500 voor Christus en 476 na Christus. (datum van de val van het West-Romeinse rijk)",
		lateRoman: "De late oudheid",
		lateRomanDesc:
			"de periode van het Romeinse Rijk dat loopt van het einde van de 3e eeuw tot de teloorgang van het Westerse Rijk in 476. Deze periode illustreert de overgang tussen de oudheid en de middeleeuwen.",
		highMiddleAges: "De vroege middeleeuwen",
		highMiddleAgesDesc:
			"de periode die loopt van het einde van de oudheid tot het einde van de karolingische periode, d.w.z. aan het einde van de 10e eeuw.",
		classicalMiddleAges: "De late middeleeuwen",
		classicalMiddleAgesDesc:
			"deze periode tussen de 11e en 13e eeuw na Christus wordt gekenmerkt door het afbrokkelen van het gezag en van het feodale systeem. Ook de rol van kastelen als militair object was uitgespeeld.",
	},
];
