/* Objects to discover */
import calcStone from "../style/assets/pois/thermalBaths/calcStone.png";
import monay from "../style/assets/pois/hauterecenne/monay.png";
import reindeer from "../style/assets/pois/nutons/reindeer.png";
import bone from "../style/assets/pois/frontal/bone.png";
import sediment from "../style/assets/pois/goldenPaddle/sediment.png";
import silex from "../style/assets/pois/reuviau/silex.png";
/* Pois img */
import thermalBathBg from "../style/assets/pois/thermalBaths/background.jpg";
import thermalBathsSliderBefore from "../style/assets/pois/thermalBaths/POI1_THERMES_AVANT.jpg";
import thermalBathsSliderAfter from "../style/assets/pois/thermalBaths/POI1_THERMES_AUJOURDHUI.jpg";
import thermalBath1 from "../style/assets/pois/thermalBaths/thermalBaths1.png";
import thermalBath2 from "../style/assets/pois/thermalBaths/thermalBaths2.png";

import hauterecenneBg from "../style/assets/pois/hauterecenne/background.jpg";
import hauterecenneSliderBefore from "../style/assets/pois/hauterecenne/POI2_FORTERESSE HAUTERECENNE_AVANT.jpg";
import hauterecenneSliderAfter from "../style/assets/pois/hauterecenne/POI2_FORTERESSE HAUTERECENNE_AUJOURDHUI.jpg";
import hauterecenne1 from "../style/assets/pois/hauterecenne/hauterecenne1.png";
import hauterecenne2 from "../style/assets/pois/hauterecenne/hauterecenne2.png";

import nutonsBg from "../style/assets/pois/nutons/background.jpg";
import nutonsSliderBefore from "../style/assets/pois/nutons/POI3_TROU DES NUTONS_AVANT.jpg";
import nutonsSliderAfter from "../style/assets/pois/nutons/POI3_TROU DES NUTONS_AUJOURDHUI.jpg";
import nutons1 from "../style/assets/pois/nutons/nutons1.png";

import frontalBg from "../style/assets/pois/frontal/background.jpg";
import frontalSliderBefore from "../style/assets/pois/frontal/POI4_TROU DU FRONTAL_AVANT.jpg";
import frontalSliderAfter from "../style/assets/pois/frontal/POI4_TROU DU FRONTAL_AUJOURDHUI.jpg";
import frontal1 from "../style/assets/pois/frontal/frontal1.png";
import frontal2 from "../style/assets/pois/frontal/frontal2.png";

import goldenPaddleBg from "../style/assets/pois/goldenPaddle/background.jpg";
import goldenPaddleSliderBefore from "../style/assets/pois/goldenPaddle/POI5_TROU DE LA GATTE DOR_AVANT.jpg";
import goldenPaddleSliderAfter from "../style/assets/pois/goldenPaddle/POI5_TROU DE LA GATTE DOR_AUJOURDHUI.jpg";
import goldenPaddle1 from "../style/assets/pois/goldenPaddle/goldenPaddle1.png";

import reuviauBg from "../style/assets/pois/reuviau/background.jpg";
import reuviauSliderBefore from "../style/assets/pois/reuviau/POI6_TROU REUVIAU_AVANT.jpg";
import reuviauSliderAfter from "../style/assets/pois/reuviau/POI6_TROU REUVIAU_AUJOURDHUI.jpg";
import reuviau1 from "../style/assets/pois/reuviau/reuviau1.png";
import reuviau2 from "../style/assets/pois/reuviau/reuviau2.png";
import reuviau3 from "../style/assets/pois/reuviau/reuviau3.png";

export const pois = [
	{
		id: "parking",
		number: "01",
		name: "parking",
		clickable: "false",
		map: {
			lat: 50.215829,
			lon: 4.956293,
			labelSide: "right",
		},
	},
	{
		id: "accueil",
		number: "01",
		name: "accueil",
		clickable: "false",
		map: {
			lat: 50.215429,
			lon: 4.956093,
			labelSide: "right",
		},
	},
	{
		parcours: 0,
		id: "thermalBaths",
		number: "01",
		name: "thermalBaths",
		title: "thermalBathsTitle",
		subtitle: "thermalBathsSubtitle",
		codes: ["pierre"],
		background: "url('" + thermalBathBg + "')",
		map: {
			lat: 50.215129,
			lon: 4.955993,
			labelSide: "left",
		},
		slider: {
			before: {
				text: "t300ad",
				color: "#2B1D16",
				img: "url('" + thermalBathsSliderBefore + "')",
				aspectRatio: 1080 / 1920,
			},
			now: {
				text: "2024",
				color: "#2B1D16",
				img: "url('" + thermalBathsSliderAfter + "')",
				aspectRatio: 1080 / 1920,
			},
		},
		riddle: {
			discover: "thermalBathsRiddleDisc",
			title: "thermalBathsRiddleTitle",
			img: "url('" + calcStone + "')",
			desc: "thermalBathsRiddleDesc",
		},
		content: [
			{
				type: "bloc",
				content: [
					{
						title: "thermalBathsSec1Title",
						subtitle: "thermalBathsSec1Content",
					},
					{
						title: "thermalBathsSec2Title",
						subtitle: "thermalBathsSec2Content",
						words: ["hypocaust"],
					},
				],
				words: ["hypocaust"],
			},
			{
				type: "img",
				title: "thermalBathsLegend1",
				url: "url('" + thermalBath1 + "')",
				ratio: 530 / 486,
			},
			{
				type: "encart",
				header: "furfoozMystery",
				title: "thermalBathsEncart1Title",
				subtitle: "thermalBathsEncart1Content",
				words: ["archaeologist", "prospecting"],
			},
			{
				type: "bloc",
				content: [
					{
						title: "thermalBathsSec3Title",
						subtitle: "thermalBathsSec3Content",
					},
				],
			},
			{
				type: "encart",
				header: "archaeology",
				title: "thermalBathsEncart2Title",
				subtitle: "thermalBathsEncart2Content",
				words: ["experimentalArchaeology", "anthropologist"],
			},
			{
				type: "bloc",
				content: [
					{
						title: "thermalBathsSec4Title",
						subtitle: "thermalBathsSec4Content",
						words: ["necropolis"],
					},
				],
				words: ["necropolis"],
			},
			{
				type: "img",
				title: "thermalBathsLegend2",
				url: "url('" + thermalBath2 + "')",
				ratio: 1125 / 807,
			},
			{
				type: "encart",
				header: "doYouKnow",
				title: "thermalBathsEncart3Title",
				subtitle: "thermalBathsEncart3Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "thermalBathsSec5Title",
						subtitle: "thermalBathsSec5Content",
					},
				],
			},
		],
	},
	{
		parcours: 1,
		id: "hauterecenne",
		number: "02",
		name: "hauterecenne",

		title: "hauterecenneTitle",
		subtitle: "hauterecenneSubtitle",
		codes: ["piece", "pièce"],
		background: "url('" + hauterecenneBg + "')",
		map: {
			lat: 50.214689,
			lon: 4.957043,
			labelSide: "right",
		},
		slider: {
			before: {
				text: "t1200ad",
				color: "#2B1D16",
				img: "url('" + hauterecenneSliderBefore + "')",
				aspectRatio: 1080 / 1920,
			},
			now: {
				text: "2024",
				color: "#2B1D16",
				img: "url('" + hauterecenneSliderAfter + "')",
				aspectRatio: 1080 / 1920,
			},
		},
		riddle: {
			discover: "hauterecenneRiddleDisc",
			title: "hauterecenneRiddleTitle",
			img: "url('" + monay + "')",
			desc: "hauterecenneRiddleDesc",
		},
		content: [
			{
				type: "quote",
				content: "hauterecenneQuote1",
				author: "hauterecenneQuoteAuthor1",
			},
			{
				type: "bloc",
				content: [
					{
						title: "hauterecenneSec1Title",
						subtitle: "hauterecenneSec1Content",
						words: ["lateRoman"],
					},
					{
						title: "hauterecenneSec2Title",
						subtitle: "hauterecenneSec2Content",
					},
				],
				words: [
					"lateRoman",
					"antiquity",
					"classicalMiddleAges",
					"highMiddleAges",
				],
			},
			{
				type: "img",
				title: "hauterecenneLegend1",
				url: "url('" + hauterecenne1 + "')",
				ratio: 1600 / 772,
			},
			{
				type: "encart",
				header: "archaeology",
				title: "hauterecenneEncart1Title",
				subtitle: "hauterecenneEncart1Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "hauterecenneSec3Title",
						subtitle: "hauterecenneSec3Content",
					},
				],
			},
			{
				type: "encart",
				header: "anecdote",
				title: "hauterecenneEncart2Title",
				subtitle: "hauterecenneEncart2Content",
			},
			{
				type: "img",
				title: "hauterecenneLegend2",
				url: "url('" + hauterecenne2 + "')",
				ratio: 1600 / 1231,
			},
			{
				type: "bloc",
				content: [
					{
						title: "hauterecenneSec4Title",
						subtitle: "hauterecenneSec4Content",
					},
					{
						title: "hauterecenneSec5Title",
						subtitle: "hauterecenneSec5Content",
					},
					{
						title: "hauterecenneSec6Title",
						subtitle: "hauterecenneSec6Content",
					},
				],
			},
		],
	},
	{
		parcours: 2,
		id: "nutons",
		number: "03",
		name: "nutons",

		title: "nutonsTitle",
		subtitle: "nutonsSubtitle",
		codes: ["bison"],
		background: "url('" + nutonsBg + "')",
		map: {
			lat: 50.213869,
			lon: 4.956893,
			labelSide: "right",
		},
		slider: {
			before: {
				text: "t10000bc",
				color: "#FDFAF7",
				img: "url('" + nutonsSliderBefore + "')",
				aspectRatio: 1080 / 1920,
			},
			now: {
				text: "2024",
				color: "#FDFAF7",
				img: "url('" + nutonsSliderAfter + "')",
				aspectRatio: 1080 / 1920,
			},
		},
		riddle: {
			discover: "nutonsRiddleDisc",
			title: "nutonsRiddleTitle",
			img: "url('" + reindeer + "')",
			desc: "nutonsRiddleDesc",
		},
		content: [
			{
				type: "bloc",
				content: [
					{ title: "nutonsSec1Title", subtitle: "nutonsSec1Content" },
					{ title: "nutonsSec2Title", subtitle: "nutonsSec2Content" },
				],
			},
			{
				type: "img",
				title: "nutonsLegend1",
				url: "url('" + nutons1 + "')",
				ratio: 547 / 512,
			},
			{
				type: "bloc",
				content: [
					{ title: "nutonsSec3Title", subtitle: "nutonsSec3Content" },
					{
						title: "nutonsSec4Title",
						subtitle: "nutonsSec4Content",
						words: ["palaeontologist"],
					},
				],
				words: ["palaeontologist", "magdalenian"],
			},
			{
				type: "encart",
				header: "edouardDupont",
				title: "nutonsEncart1Title",
				subtitle: "nutonsEncart1Content",
			},
			{
				type: "quote",
				content: "nutonsQuote1",
			},
		],
	},
	{
		parcours: 3,
		id: "frontal",
		number: "04",
		name: "frontal",

		title: "frontalTitle",
		subtitle: "frontalSubtitle",
		codes: ["frontal"],
		background: "url('" + frontalBg + "')",
		map: {
			lat: 50.212009,
			lon: 4.956123,
			labelSide: "right",
		},
		slider: {
			before: {
				text: "t10000bc",
				color: "#FDFAF7",
				img: "url('" + frontalSliderBefore + "')",
				aspectRatio: 1080 / 1920,
			},
			now: {
				text: "2024",
				color: "#FDFAF7",
				img: "url('" + frontalSliderAfter + "')",
				aspectRatio: 1080 / 1920,
			},
		},
		riddle: {
			discover: "frontalRiddleDisc",
			title: "frontalRiddleTitle",
			img: "url('" + bone + "')",
			desc: "frontalRiddleDesc",
		},
		content: [
			{
				type: "bloc",
				content: [
					{
						title: "frontalSec1Title",
						subtitle: "frontalSec1Subtitle",
					},
					{
						title: "frontalSec2Title",
						subtitle: "frontalSec2Subtitle",
					},
				],
			},
			{
				type: "img",
				title: "frontalLegend1",
				url: "url('" + frontal1 + "')",
				ratio: 1025 / 1522,
			},
			{
				type: "encart",
				header: "edouardDupont",
				title: "frontalEncart1Title",
				subtitle: "frontalEncart1Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "frontalSec3Title",
						subtitle: "frontalSec3Subtitle",
					},
				],
			},
			{
				type: "encart",
				header: "doYouKnow",
				title: "frontalEncart2Title",
				subtitle: "frontalEncart2Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "frontalSec4Title",
						subtitle: "frontalSec4Subtitle",
					},
				],
			},
			{
				type: "img",
				title: "frontalLegend2",
				url: "url('" + frontal2 + "')",
				ratio: 797 / 618,
			},
			{
				type: "bloc",
				content: [
					{
						title: "frontalSec5Title",
						subtitle: "frontalSec5Subtitle",
					},
					{
						title: "frontalSec6Title",
						subtitle: "frontalSec6Subtitle",
					},
				],
			},
		],
	},
	{
		parcours: 4,
		id: "goldenPaddle",
		number: "05",
		name: "goldenPaddle",

		title: "goldenPaddleTitle",
		subtitle: "goldenPaddleSubtitle",
		codes: ["sédiment", "sediment"],
		background: "url('" + goldenPaddleBg + "')",
		map: {
			lat: 50.211209,
			lon: 4.954923,
			labelSide: "left",
		},
		slider: {
			before: {
				text: "t10000bc",
				color: "#FDFAF7",
				img: "url('" + goldenPaddleSliderBefore + "')",
				aspectRatio: 1080 / 1920,
			},
			now: {
				text: "2024",
				color: "#FDFAF7",
				img: "url('" + goldenPaddleSliderAfter + "')",
				aspectRatio: 1080 / 1920,
			},
		},
		riddle: {
			discover: "goldenPaddleRiddleDisc",
			title: "goldenPaddleRiddleTitle",
			img: "url('" + sediment + "')",
			desc: "goldenPaddleRiddleDesc",
		},
		content: [
			{
				type: "bloc",
				content: [
					{
						title: "goldenPaddleSec1Title",
						subtitle: "goldenPaddleSec1Subtitle",
					},
				],
			},
			{
				type: "img",
				title: "goldenPaddleLegend1",
				url: "url('" + goldenPaddle1 + "')",
				ratio: 797 / 618,
			},
			{
				type: "bloc",
				content: [
					{
						title: "goldenPaddleSec2Title",
						subtitle: "goldenPaddleSec2Subtitle",
					},
					{
						title: "goldenPaddleSec3Title",
						subtitle: "goldenPaddleSec3Subtitle",
					},
				],
			},
			{
				type: "encart",
				header: "anecdote",
				title: "goldenPaddleEncart1Title",
				subtitle: "goldenPaddleEncart1Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "goldenPaddleSec4Title",
						subtitle: "goldenPaddleSec4Subtitle",
					},
				],
			},
		],
	},
	{
		parcours: 5,
		id: "reuviau",
		number: "06",
		name: "reuviau",

		title: "reuviauTitle",
		subtitle: "reuviauSubtitle",
		codes: ["silex"],
		background: "url('" + reuviauBg + "')",
		map: {
			lat: 50.214509,
			lon: 4.955223,
			labelSide: "left",
		},
		slider: {
			before: {
				text: "t10000bc",
				color: "#FDFAF7",
				img: "url('" + reuviauSliderBefore + "')",
				aspectRatio: 1080 / 1920,
			},
			now: {
				text: "2024",
				color: "#FDFAF7",
				img: "url('" + reuviauSliderAfter + "')",
				aspectRatio: 1080 / 1920,
			},
		},
		riddle: {
			discover: "reuviauRiddleDisc",
			title: "reuviauRiddleTitle",
			img: "url('" + silex + "')",
			desc: "reuviauRiddleDesc",
		},
		content: [
			{
				type: "bloc",
				content: [
					{
						title: "reuviauSec1Title",
						subtitle: "reuviauSec1Subtitle",
					},
				],
			},
			{
				type: "img",
				title: "reuviauLegend1",
				url: "url('" + reuviau1 + "')",
				ratio: 1600 / 1067,
			},
			{
				type: "encart",
				header: "edouardDupont",
				title: "reuviauEncart1Title",
				subtitle: "reuviauEncart1Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "reuviauSec2Title",
						subtitle: "reuviauSec2Subtitle",
					},
				],
			},
			{
				type: "encart",
				header: "edouardDupont",
				title: "reuviauEncart2Title",
				subtitle: "reuviauEncart2Content",
			},
			{
				type: "bloc",
				content: [
					{
						title: "reuviauSec3Title",
						subtitle: "reuviauSec3Subtitle",
					},
				],
			},
			{
				type: "img",
				title: "reuviauLegend2",
				url: "url('" + reuviau2 + "')",
				ratio: 1600 / 738,
			},
			{
				type: "bloc",
				content: [
					{
						title: "reuviauSec4Title",
						subtitle: "reuviauSec4Subtitle",
					},
				],
			},
			{
				type: "img",
				title: "reuviauLegend3",
				url: "url('" + reuviau3 + "')",
				ratio: 540 / 405,
			},
		],
	},
];
